import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';

import CropSquareIcon from '@mui/icons-material/CropSquare';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { useGlobalContext } from '../../context/global_context';
import StarIcon from '@mui/icons-material/Star';

import { Link } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles({
    actionPanelWrapper: {
        position: 'fixed',
        top: 70,
        left: '11.9%',
        bottom: 0,
        minWidth: '30%',
        maxWidth: '30%',
        boxShadow: '2px 2px 2px 2px #e6e6e6',
        padding: '16px',
        overflowY: 'scroll'
    },
    generateButtonWrapper: {
        minWidth: '30%',
        maxWidth: '30%',
        boxShadow: '2px 2px 2px 2px #e6e6e6',
        alignContent: 'center',
        padding: '16px',
        position: 'fixed',
        bottom: 0,
        left: '11.9%',
        backgroundColor: '#eee'
    },
    sideBtn: {
        fontSize: '14px',
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bottomBtn: {
        fontSize: '10px',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    innerDiv: {
        boxShadow: '0 0 2px 2px #d6d6d6',
        padding: '8px',
        color: '#000'
    }
});

const CustomSliderStyles = {
    '& .MuiSlider-markLabel': {
        fontSize: "10px",
        fontWeight: 'bold'
    }
};

const ActionPanel = () => {
    const classes = useStyles();
    const [ok, setOk] = useState(true);
    const [paidUserDialog, setPaidUserDialog] = useState(false);

    const [faceCreateAdvanceOptions, setFaceCreateAdvanceOptions] = useState(false);
    const [anyAIPhotoAdvanceOptions, setAnyAIPhotoAdvanceOptions] = useState(false);
    const [faceCreateStyles, setFaceCreateStyles] = useState(false);
    const [faceCreateInspirations, setFaceCreateInspirations] = useState(false);
    const [anyAIPhotoStyles, setAnyAIPhotoStyles] = useState(false);
    const [anyAIPhotoInspirations, setAnyAIPhotoInspirations] = useState(false);

    const {
        user,
        demo,
        isMobile,
        activeMenu,
        updateDemoPrompt,
        onFaceImageUploaded,
        removeFacePhoto,
        generateNewPhotos,
        updateDemoWidth,
        updateDemoHeight,
        onBaseImageUploaded,
        removeBasePhoto,
        generateReplacedPhoto,
        onTilingSelection,
        onDenoisingStrengthChange,
        onReferenceImageUploaded,
        removeReferencePhoto,
        generateAnyAIPhotos,
        uploads,
        selectUploadedImage,
        selectedPhoto,
        updateAspectRatio,
        setRecentUploadsDisplay,
        themes,
        selectedTheme,
        updateSelectedTheme,
        randomPrompts,
        onFaceLocationChanged,
        isNsfw,
        examples,
        fetchExamples
    } = useGlobalContext();

    if (examples.length === 0) {
        fetchExamples();
    }

    const denoisingStrengthLabel = [
        {
            value: 0,
            label: '0% (no change)'

        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 100,
            label: '100% (completely change)',
        },
    ];

    const handleThemeClick = (theme) => {
        if (theme && theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0) {
            setPaidUserDialog(true);
        } else {
            updateSelectedTheme(theme)
        }
    }

    const [selectedExample, setSelectedExample] = useState("");

    const handleCopyPrompt = (id, prompt) => {
        navigator.clipboard.writeText(prompt);
        setSelectedExample(id)
    }

    return (<>
        <div>
            <Grid
                container
                direction={(isMobile == false) ? "column" : "row"}
                justifyContent="flex-start"
                alignItems='flex-start'
            >
                <Grid item>
                    {activeMenu === 'ai-portrait' &&
                        <div>
                            <div className={classes.actionPanelWrapper}>
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>1/5. Explain Expected Photo </b>
                                    <span style={{ fontSize: '12px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => updateDemoPrompt(undefined, "actionPanelAIPortrait", randomPrompts.instaportraitai[Math.floor(Math.random() * randomPrompts.instaportraitai.length)])}>Try Random</span>
                                    <textarea style={{ border: (isNsfw.actionPanelAIPortrait) ? '2px solid red' : '' }} type="text" multiple rows={2} placeholder="example - woman in business suit, wearing earphones, looking into camera" value={demo?.prompt} onChange={(e) => updateDemoPrompt(e, "actionPanelAIPortrait")} />
                                    {isNsfw.actionPanelAIPortrait && <span style={{ fontSize: '12px', color: 'red' }}><ErrorIcon size='small' /> Unethical Or NSFW detected.</span>}
                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>2/5. Face Photo (Optional)</b>{(!demo.facePhotoLink || demo.facePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                                    {!demo.facePhotoLink ?
                                        <><div style={{ maxWidth: '100%' }} className="content">
                                            {uploads
                                                && uploads.length > 0
                                                && <>
                                                    <Checkbox
                                                        id='recentUploadsDisplay'
                                                        size='large'
                                                        onClick={(e) => setRecentUploadsDisplay(e, "FACE")} // relevant method to handle your change
                                                        color="primary"
                                                        defaultChecked={demo.recentUploadsDisplay}
                                                    />
                                                    <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                                    <br />
                                                    {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                        return <>
                                                            <img
                                                                key={i}
                                                                onClick={() => selectUploadedImage({
                                                                    index: i,
                                                                    photoType: "FACE",
                                                                    subMenu: "AI_FACE_PHOTO",
                                                                    link: photo.link,
                                                                    filename: photo.filename,
                                                                    width: photo.width,
                                                                    height: photo.height
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '2px',
                                                                    margin: '5px',
                                                                    border: (selectedPhoto
                                                                        && selectedPhoto.FACE
                                                                        && selectedPhoto.FACE.index === i
                                                                        && selectedPhoto.FACE.photoType === "FACE"
                                                                        && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO")
                                                                        ? '2px solid #8c52ff' : '1px solid #eee',
                                                                    width: (selectedPhoto
                                                                        && selectedPhoto.FACE
                                                                        && selectedPhoto.FACE.index === i
                                                                        && selectedPhoto.FACE.photoType === "FACE"
                                                                        && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO") ? '50px' : '40px'
                                                                }}
                                                                src={photo.link} />&nbsp;&nbsp;</>
                                                    })}
                                                    <br />
                                                    <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                        </div>
                                            <button onClick={() => document.getElementById('face-photo').click()} className='edu-btn btn-small'>Select/Click Face Image</button>
                                            <input onChange={onFaceImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="face-photo" name="face-photo" />
                                        </> : <>
                                            <br />
                                            <img style={{ padding: '10px' }} src={demo.facePhotoLink} width='60px'></img>
                                            <u style={{ fontSize: '12px', color: '#525FE1', cursor: 'pointer' }} onClick={removeFacePhoto}>Remove Selected Photo</u>
                                        </>}

                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>3/5. Output Photo Configs (Optional)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>

                                    <Checkbox
                                        id='face-create-advance-options'
                                        size='large'
                                        onClick={() => setFaceCreateAdvanceOptions(!faceCreateAdvanceOptions)}
                                        color="primary"
                                        defaultChecked={faceCreateAdvanceOptions}
                                    />
                                    <span style={{ fontSize: '12px' }}>Show</span>
                                    {faceCreateAdvanceOptions && <>
                                        <br />
                                        <label for='width'>Aspect Ratio:&nbsp;</label>
                                        <br />
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'SQUARE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('SQUARE')}><CropSquareIcon />Square 1:1</button>&nbsp;
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'PORTRAIT') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('PORTRAIT')}><CropPortraitIcon />Portrait 3:4</button>&nbsp;
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'LANDSCAPE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('LANDSCAPE')}><CropLandscapeIcon />Landscape 4:3</button>&nbsp;
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'CUSTOM') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('CUSTOM')}>Custom</button>
                                        <br />
                                        <label>Dimension(px): </label>
                                        <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoWidth} type="text" id='width' placeholder='width(px)' value={demo.width} />
                                        x
                                        <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoHeight} type="text" id='height' placeholder='height(px)' value={demo.height} />
                                    </>}
                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>4/5. Image Style</b>
                                    <Checkbox
                                        id='face-create-styles'
                                        size='large'
                                        onClick={() => setFaceCreateStyles(!faceCreateStyles)}
                                        color="primary"
                                        defaultChecked={faceCreateStyles}
                                    />
                                    <span style={{ fontSize: '12px' }}>Show</span>
                                    <br />
                                    {faceCreateStyles && 
                                    <div>
                                        <Grid container direction='row' spacing={2}>
                                            {themes.instaportraitai && themes.instaportraitai.length > 0 &&
                                                themes.instaportraitai.map((theme, index) => {
                                                    return <Grid item xs={4} key={theme._id}>
                                                        <div style={{ border: (selectedTheme && selectedTheme._id === theme._id) ? '2px solid #8c52ff' : '1px solid #eee', padding: '4px', cursor: 'pointer' }} onClick={() => { handleThemeClick(theme) }}>
                                                            <span style={{ fontSize: '8px', fontWeight:'bold' }}>{(index + 1)}/{themes.instaportraitai?.length}. {theme.caption}</span>
                                                            <img src={theme.imageAWSLink[0]} width={(theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0) ? '83%' : '100%'}></img>
                                                            {theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0 && <span style={{ fontSize: '8px' }}><br /><StarIcon color='secondary' />For Paid Users</span>}
                                                        </div>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </div>}
                                </div>

                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>5/5. Inspirational Prompts</b>
                                    <Checkbox
                                        id='face-create-inspirational'
                                        size='large'
                                        onClick={() => setFaceCreateInspirations(!faceCreateInspirations)}
                                        color="primary"
                                        defaultChecked={faceCreateInspirations}
                                    />
                                    <span style={{ fontSize: '12px' }}>Show</span>
                                    <br />
                                    {faceCreateInspirations && 
                                        <div style={{ overflowX: 'hidden', overflowY: 'scroll' }}>
                                        <Grid container direction='row' spacing={0}>
                                            {examples.map((ex, i) => {
                                                return <Grid key={ex.id} item xs={10} lg={5} style={{ border: '1px solid #6F6B80', padding: '8px', margin: '4px' }}>
                                                    <Grid container direction='column'>
                                                        <b  style={{ fontSize: '10px' }}>{(i + 1)}/{examples.length}: {ex.caption}</b>
                                                        <img src={ex.imageAWSLink} />
                                                        <span style={{ fontSize: '12px' }}><b>Prompt:</b> {ex.prompt}</span>
                                                        {selectedExample === ex.id ?
                                                            <b style={{ color: '#7152E9' }}><CheckIcon />Prompt Copied</b>
                                                            : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(ex.id, ex.prompt)}><ContentCopyIcon />Copy Prompt</button>}

                                                    </Grid>
                                                    <br />
                                                </Grid>
                                            })}
                                        </Grid>
                                    </div>
                                    }
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className={classes.generateButtonWrapper} style={{}}>
                                {!demo.facePhotoLink && <span style={{ color: 'red', fontSize: '12px' }}>Select face-photo to get portrait with a particular face.</span>}
                                <br />
                                <Checkbox
                                    id='ok_new_photo'
                                    size='large'
                                    onClick={() => setOk(!ok)}
                                    color="primary"
                                    defaultChecked={ok}
                                />
                                <span style={{ fontSize: '12px' }}>I take the complete legal responsibility & authority of the image.</span>
                                <br />
                                <button
                                    className='edu-btn btn-medium btn-secondary'
                                    disabled={!ok}
                                    style={{ opacity: ok ? '100%' : '30%', width: '100%' }}
                                    onClick={generateNewPhotos}>Generate New Photos</button>
                                <br />
                            </div>
                        </div>
                    }
                    {activeMenu === 'face-replacement' &&
                        <div>
                            <div className={classes.actionPanelWrapper}>
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>

                                    <b>1/2. Base Photo</b>{(!demo.basePhotoLink || demo.basePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                                    {!demo.basePhotoLink ?
                                        <>
                                            <div style={{ maxWidth: '100%' }} className="content">
                                                {uploads
                                                    && uploads.length > 0
                                                    && <><Checkbox
                                                        id='recentUploadsDisplay'
                                                        size='large'
                                                        onClick={(e) => setRecentUploadsDisplay(e, "BASE")} // relevant method to handle your change
                                                        color="primary"
                                                        defaultChecked={demo.recentUploadsDisplay}
                                                    />
                                                        <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                                        <br />
                                                        {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                            return <>
                                                                <img
                                                                    key={i}
                                                                    onClick={() => selectUploadedImage({
                                                                        index: i,
                                                                        photoType: "BASE",
                                                                        subMenu: "FACE_REPLACEMENT",
                                                                        link: photo.link,
                                                                        filename: photo.filename,
                                                                        width: photo.width,
                                                                        height: photo.height
                                                                    })}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        padding: '2px',
                                                                        margin: '5px',
                                                                        border: (selectedPhoto
                                                                            && selectedPhoto.BASE
                                                                            && selectedPhoto.BASE.index === i
                                                                            && selectedPhoto.BASE.photoType === "BASE"
                                                                            && selectedPhoto.BASE.subMenu === "FACE_REPLACEMENT")
                                                                            ? '2px solid #8c52ff' : '1px solid #eee',
                                                                        width: (selectedPhoto
                                                                            && selectedPhoto.BASE
                                                                            && selectedPhoto.BASE.index === i
                                                                            && selectedPhoto.BASE.photoType === "BASE"
                                                                            && selectedPhoto.BASE.subMenu === "FACE_REPLACEMENT") ? '60px' : '50px'
                                                                    }}
                                                                    src={photo.link} />&nbsp;&nbsp;</>
                                                        })}
                                                        <br />
                                                        <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                            </div>
                                            <button onClick={() => document.getElementById('base-photo').click()} className='edu-btn btn-small'>Select Base Image</button>
                                            <input style={{ display: 'none' }} onChange={onBaseImageUploaded} type="file" accept="image/png, image/jpeg" id="base-photo" name="base-photo" />
                                        </> : <>
                                            <br />
                                            <img style={{ padding: '10px' }} src={demo.basePhotoLink} width='60px'></img>
                                            <u style={{ fontSize: '12px', color: '#525FE1', cursor: 'pointer' }} onClick={removeBasePhoto}>Remove Selected Photo</u>
                                        </>}
                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>2/2. Face Photo</b>{(!demo.facePhotoLink || demo.facePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                                    {!demo.facePhotoLink ?
                                        <>
                                            <div style={{ maxWidth: '100%' }} className="content">
                                                {uploads
                                                    && uploads.length > 0
                                                    && <>
                                                        <Checkbox
                                                            id='recentUploadsDisplay'
                                                            size='large'
                                                            onClick={(e) => setRecentUploadsDisplay(e, "FACE")} // relevant method to handle your change
                                                            color="primary"
                                                            defaultChecked={demo.recentUploadsDisplay}
                                                        />
                                                        <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                                        <br />
                                                        {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                            return <>
                                                                <img
                                                                    key={i}
                                                                    onClick={() => selectUploadedImage({
                                                                        index: i,
                                                                        photoType: "FACE",
                                                                        subMenu: "FACE_REPLACEMENT",
                                                                        link: photo.link,
                                                                        filename: photo.filename,
                                                                        width: photo.width,
                                                                        height: photo.height
                                                                    })}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        padding: '2px',
                                                                        margin: '5px',
                                                                        border: (selectedPhoto
                                                                            && selectedPhoto.FACE
                                                                            && selectedPhoto.FACE.index === i
                                                                            && selectedPhoto.FACE.photoType === "FACE"
                                                                            && selectedPhoto.FACE.subMenu === "FACE_REPLACEMENT")
                                                                            ? '2px solid #8c52ff' : '1px solid #eee',
                                                                        width: (selectedPhoto
                                                                            && selectedPhoto.FACE
                                                                            && selectedPhoto.FACE.index === i
                                                                            && selectedPhoto.FACE.photoType === "FACE"
                                                                            && selectedPhoto.FACE.subMenu === "FACE_REPLACEMENT") ? '50px' : '40px'
                                                                    }}
                                                                    src={photo.link} />&nbsp;&nbsp;</>
                                                        })}
                                                        <br />
                                                        <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                            </div>
                                            <button onClick={() => document.getElementById('face-photo').click()} className='edu-btn btn-small'>Select Face Image</button>
                                            <input style={{ display: 'none' }} onChange={onFaceImageUploaded} type="file" accept="image/png, image/jpeg" id="face-photo" name="face-photo" />
                                        </> : <>
                                            <br />
                                            <img style={{ padding: '10px' }} src={demo.facePhotoLink} width='60px'></img>
                                            <u style={{ fontSize: '12px', color: '#525FE1', cursor: 'pointer' }} className='edu-btn btn-small' onClick={removeFacePhoto}>Remove Selected Photo</u>
                                        </>}
                                </div>
                            </div>
                            <div className={classes.generateButtonWrapper}>
                                {(!demo.basePhotoLink || !demo.facePhotoLink) && <span style={{ color: 'red', fontSize: '12px' }}><ErrorOutlineIcon />Select all required photos.</span>}
                                <br />
                                <Checkbox
                                    id='ok_new_photo'
                                    size='large'
                                    onClick={() => setOk(!ok)}
                                    color="primary"
                                    defaultChecked={ok}
                                />
                                <span style={{ fontSize: '12px' }}>I take the complete legal responsibility & authority of the image.</span>
                                <br />
                                <button
                                    disabled={!demo.basePhotoLink || !demo.facePhotoLink || !ok}
                                    style={{
                                        width: '100%', opacity: (!demo.basePhotoLink || !demo.facePhotoLink || !ok) ? '30%' : "100%",
                                        cursor: (!demo.basePhotoLink || !demo.facePhotoLink || !ok) ? '' : "pointer"
                                    }}
                                    className='edu-btn btn-medium btn-secondary'
                                    onClick={generateReplacedPhoto}>Generate Replaced Photo</button>
                            </div>
                        </div>
                    }
                    {activeMenu === 'ai-photos' &&
                        <div>
                            <div className={classes.actionPanelWrapper}>
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>1/5. Explain Expected Photo </b>
                                    <span style={{ fontSize: '12px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => updateDemoPrompt(undefined, "actionPanelAnyAIPhoto", randomPrompts.anyAIPhoto[Math.floor(Math.random() * randomPrompts.anyAIPhoto.length)])}>Try Random</span>
                                    <textarea style={{ border: (isNsfw.actionPanelAnyAIPhoto) ? '2px solid red' : '' }} type="text" multiple rows={2} placeholder="example - woman in business suit, wearing earphones, looking into camera" value={demo?.prompt} onChange={(e) => updateDemoPrompt(e, "actionPanelAnyAIPhoto")} />
                                    {isNsfw.actionPanelAnyAIPhoto && <span style={{ fontSize: '12px', color: 'red' }}><ErrorIcon size='small' /> Unethical Or NSFW detected.</span>}
                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>2a/5. Reference Photo (Optional)</b>{(!demo.referencePhotoLink || demo.referencePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                                    {!demo.referencePhotoLink ? <>
                                        <div style={{ maxWidth: '100%' }} className="content">
                                            {uploads
                                                && uploads.length > 0
                                                && <>
                                                    <Checkbox
                                                        id='recentUploadsDisplay'
                                                        size='large'
                                                        onClick={(e) => setRecentUploadsDisplay(e, "REFERENCE")} // relevant method to handle your change
                                                        color="primary"
                                                        defaultChecked={demo.recentUploadsDisplay}
                                                    />
                                                    <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                                    <br />
                                                    {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                        return <>
                                                            <img
                                                                key={i}
                                                                onClick={() => selectUploadedImage({
                                                                    index: i,
                                                                    photoType: "REFERENCE",
                                                                    subMenu: "ANY_AI_PHOTO",
                                                                    link: photo.link,
                                                                    filename: photo.filename,
                                                                    width: photo.width,
                                                                    height: photo.height
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '2px',
                                                                    margin: '5px',
                                                                    border: (selectedPhoto
                                                                        && selectedPhoto.REFERENCE
                                                                        && selectedPhoto.REFERENCE.index === i
                                                                        && selectedPhoto.REFERENCE.photoType === "REFERENCE"
                                                                        && selectedPhoto.REFERENCE.subMenu === "ANY_AI_PHOTO")
                                                                        ? '2px solid #8c52ff' : '1px solid #eee',
                                                                    width: (selectedPhoto
                                                                        && selectedPhoto.REFERENCE
                                                                        && selectedPhoto.REFERENCE.index === i
                                                                        && selectedPhoto.REFERENCE.photoType === "REFERENCE"
                                                                        && selectedPhoto.REFERENCE.subMenu === "ANY_AI_PHOTO") ? '50px' : '40px'
                                                                }}
                                                                src={photo.link} />&nbsp;&nbsp;</>
                                                    })}
                                                    <br />
                                                    <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                        </div>
                                        <button onClick={() => document.getElementById('reference-photo').click()} className='edu-btn btn-small'>Select Reference Image</button>
                                        <input onChange={onReferenceImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="reference-photo" name="reference-photo" />
                                    </> : <>
                                        <br />
                                        <img style={{ padding: '10px' }} src={demo.referencePhotoLink} width='60px'></img>
                                        <u style={{ fontSize: '12px', color: '#525FE1', cursor: 'pointer' }} onClick={removeReferencePhoto}>Remove Selected Photo</u>
                                    </>}
                                    {demo.referencePhotoLink &&
                                        <div style={{ minWidth: '100%' }} className="content">
                                            <br /><b>2b/4. Reference Photo Change Meter</b>
                                            <div style={{ margin: '0 15% 0 15%', maxWidth: '70%' }} className="content">
                                                <Slider
                                                    sx={CustomSliderStyles}
                                                    size="large"
                                                    aria-label="denoising_strength"
                                                    defaultValue={demo.denoising_strength}
                                                    min={0}
                                                    max={100}
                                                    valueLabelDisplay="auto"
                                                    color="secondary"
                                                    marks={denoisingStrengthLabel}
                                                    onChange={onDenoisingStrengthChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>3/5. Face Photo (Optional)</b>{(!demo.facePhotoLink || demo.facePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                                    {!demo.facePhotoLink ?
                                        <><div style={{ maxWidth: '100%' }} className="content">
                                            {uploads
                                                && uploads.length > 0
                                                && <>
                                                    <Checkbox
                                                        id='recentUploadsDisplay'
                                                        size='large'
                                                        onClick={(e) => setRecentUploadsDisplay(e, "FACE")} // relevant method to handle your change
                                                        color="primary"
                                                        defaultChecked={demo.recentUploadsDisplay}
                                                    />
                                                    <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                                    <br />
                                                    {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                        return <>
                                                            <img
                                                                key={i}
                                                                onClick={() => selectUploadedImage({
                                                                    index: i,
                                                                    photoType: "FACE",
                                                                    subMenu: "AI_FACE_PHOTO",
                                                                    link: photo.link,
                                                                    filename: photo.filename,
                                                                    width: photo.width,
                                                                    height: photo.height
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '2px',
                                                                    margin: '5px',
                                                                    border: (selectedPhoto
                                                                        && selectedPhoto.FACE
                                                                        && selectedPhoto.FACE.index === i
                                                                        && selectedPhoto.FACE.photoType === "FACE"
                                                                        && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO")
                                                                        ? '2px solid #8c52ff' : '1px solid #eee',
                                                                    width: (selectedPhoto
                                                                        && selectedPhoto.FACE
                                                                        && selectedPhoto.FACE.index === i
                                                                        && selectedPhoto.FACE.photoType === "FACE"
                                                                        && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO") ? '50px' : '40px'
                                                                }}
                                                                src={photo.link} />&nbsp;&nbsp;</>
                                                    })}
                                                    <br />
                                                    <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                        </div>
                                            <button onClick={() => document.getElementById('face-photo').click()} className='edu-btn btn-small'>Select/Click Face Image</button>
                                            <input onChange={onFaceImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="face-photo" name="face-photo" />
                                        </> : <>
                                            <br />
                                            <img style={{ padding: '10px' }} src={demo.facePhotoLink} width='60px'></img>
                                            <u style={{ fontSize: '12px', color: '#525FE1', cursor: 'pointer' }} onClick={removeFacePhoto}>Remove Selected Photo</u>
                                        </>}

                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>4/5. Output Photo Configs (Optional)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                    <Checkbox
                                        id='any-ai-advance-options'
                                        size='large'
                                        onClick={() => setAnyAIPhotoAdvanceOptions(!anyAIPhotoAdvanceOptions)}
                                        color="primary"
                                        defaultChecked={anyAIPhotoAdvanceOptions}
                                    />
                                    <span style={{ fontSize: '12px' }}>Show</span>
                                    {anyAIPhotoAdvanceOptions && <>


                                        <br />
                                        {/* <label for='count'>Output Images Count (max 2): </label>
                            <input style={{ maxWidth: '23%', margin: '5px' }} onChange={updateDemoCount} type="number" min={1} max={2} id='count' placeholder='1'/ >
                            <br /> */}
                                        <span>Tiling</span>
                                        <Checkbox
                                            id='tiling'
                                            size='large'
                                            onClick={onTilingSelection} // relevant method to handle your change
                                            color="secondary"
                                            defaultChecked={demo.tiling}
                                        />
                                        <br />
                                        <label for='width'>Aspect Ratio:&nbsp;</label>
                                        <br />
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'SQUARE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('SQUARE')}><CropSquareIcon />Square 1:1</button>&nbsp;
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'PORTRAIT') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('PORTRAIT')}><CropPortraitIcon />Portrait 3:4</button>&nbsp;
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'LANDSCAPE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('LANDSCAPE')}><CropLandscapeIcon />Landscape 4:3</button>&nbsp;
                                        <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'CUSTOM') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('CUSTOM')}>Custom</button>
                                        <br />
                                        <label for='width'>Dimension (px)</label>
                                        <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoWidth} type="text" id='width' placeholder={demo.width} />
                                        x
                                        <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoHeight} type="text" id='height' placeholder={demo.height} />
                                    </>}

                                </div>
                                <br />
                                <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                    <b>5/5. Inspirational Prompts</b>
                                    <Checkbox
                                        id='any-ai-inspiration'
                                        size='large'
                                        onClick={() => setAnyAIPhotoInspirations(!anyAIPhotoInspirations)}
                                        color="primary"
                                        defaultChecked={anyAIPhotoInspirations}
                                    />
                                    <span style={{ fontSize: '12px' }}>Show</span>
                                    <br />
                                    {anyAIPhotoInspirations
                                    && <div style={{ overflowX: 'hidden', overflowY: 'scroll' }}>
                                        <Grid container direction='row' spacing={0}>
                                            {examples.map((ex, i) => {
                                                return <Grid key={ex.id} item xs={10} lg={5} style={{ border: '1px solid #6F6B80', padding: '8px', margin: '4px' }}>
                                                    <Grid container direction='column'>
                                                        <b  style={{ fontSize: '10px' }}>{(i + 1)}/{examples.length}: {ex.caption}</b>
                                                        <img src={ex.imageAWSLink} />
                                                        <span style={{ fontSize: '12px' }}><b>Prompt:</b> {ex.prompt}</span>
                                                        {selectedExample === ex.id ?
                                                            <b style={{ color: '#7152E9' }}><CheckIcon />Prompt Copied</b>
                                                            : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(ex.id, ex.prompt)}><ContentCopyIcon />Copy Prompt</button>}

                                                    </Grid>
                                                    <br />
                                                </Grid>
                                            })}
                                        </Grid>
                                    </div>}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className={classes.generateButtonWrapper}>
                                <Checkbox
                                    id='ok_new_photo'
                                    size='large'
                                    onClick={() => setOk(!ok)}
                                    color="primary"
                                    defaultChecked={ok}
                                />
                                <span style={{ fontSize: '12px' }}>I take the complete legal responsibility & authority of the image.</span>
                                <button className='edu-btn btn-medium btn-secondary'
                                    disabled={!ok}
                                    style={{
                                        width: '100%', opacity: !ok ? '30%' : "100%",
                                        cursor: !ok ? '' : "pointer"
                                    }}
                                    onClick={generateAnyAIPhotos}>Generate AI Photos</button>
                            </div>
                            <br />
                        </div>
                    }
                </Grid>

            </Grid>
        </div>
        <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={paidUserDialog} onClose={() => setPaidUserDialog(false)}>
            <DialogTitle><b className='title'>Premium Feature</b></DialogTitle>
            <DialogContent>
                <div>
                    <span>Upgrade to a paid user and use all the premium features.</span>
                </div>
            </DialogContent>
            <DialogActions>
                <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { setPaidUserDialog(false); }}>Close</span>
                <Link to="/pricing" onClick={() => { setPaidUserDialog(true); }}>
                    <button className="edu-btn btn-primary btn-small">
                        Explore Pricing
                    </button>
                </Link>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default ActionPanel;
