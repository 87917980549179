import React, {useState} from 'react'
import SEO from '../../common/SEO';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useGlobalContext } from '../../context/global_context';
import Grid from '@material-ui/core/Grid'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Link } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import Groups2Icon from '@mui/icons-material/Groups2';

const useStyles = makeStyles({
    innerDiv: {
        // boxShadow: '0 0 2px 2px #d6d6d6',
        padding: '8px',
        color: '#000'
    }
});

async function downloadPhoto(imageUrl) {
    console.log("imageUrl: ", imageUrl);

    fetch(imageUrl, {
        "mode": "cors",
        "headers": {
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
            'Cache-Control': 'no-cache'
        }
    })
        .then(response => response.blob())
        .then(blob => {
            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // Extract the filename from the URL
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

            // Set the download attribute and filename
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            // Simulate a click on the anchor element to start the download
            link.click();

            // Clean up the temporary anchor element
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading image:', error);
        });
};

const getpaginationHeader = (itemsCount, fetchUserPhotos, pageNumber, setPageNumber) => {
    const array = []
    for (let i = 0; i < itemsCount/20; i++) {
        array.push(i);
    }
    return <>
    {array.map(a => {
        return <button className={(pageNumber === a) ? 'edu-btn btn-small' : ''} onClick={() => {fetchUserPhotos(a); setPageNumber(a)}}>{(a + 1)}</button>
    })}
    <br/>
    <br/>
    </>
}

function History() {
    const [selectedExample, setSelectedExample] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const classes = useStyles();

    const {
        user,
        path,
        updatePath,
        fetchUserPhotos,
        updatedActiveMenu,
        addImageToTeam
    } = useGlobalContext();

    if (path != '/history') {
        updatePath('/history');
        updatedActiveMenu('none')
    }

    if(!user.createdPhotos) {
        fetchUserPhotos();
    }

    const handleCopyPrompt = (id, prompt) => {
        navigator.clipboard.writeText(prompt);
        setSelectedExample(id)
    }
  return (
    <>
        <SEO title="InstaPhotoAI" />
            <LayoutAfterLogin>
                <BreadcrumbOne 
                    title="My History"
                    parentUrl="Home"
                    currentUrl="My History"
                />
                <div className="login-register-page-wrapper edu-section-gap bg-color-white">
                {user.membership 
                && user.membership.type === "FREE"
                    && <span style={{color:"blue"}}>**You will be able to access only the latest 20 shared images. <a href="/pricing" target="_blank" >
                        <b><u>Become a Pro</u></b></a> to access unlimited images.<br/><br/></span>}
                    {user.totalPhotosGenerated
                    && getpaginationHeader(user.totalPhotosGenerated, fetchUserPhotos, pageNumber, setPageNumber)} 
   
                    <Link to='/instaphotoai-teams'><button className='edu-btn btn-small btn-secondary'><Groups2Icon fontSize='large' />Check Team's Gallery</button></Link>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button className='edu-btn btn-small' onClick={() => fetchUserPhotos(0)}><RefreshIcon/>Refresh History</button>
                    <br/>
                    <br/>
                
                    <div className="container checkout-page-style">
                        <Grid container direction='row' 
                spacing={1}>
                            {user.createdPhotos && user.createdPhotos.map((ex,i) => {
                                return <Grid key={ex._id} item xs={6} md={3} style={{border:'1px solid #6F6B80'}}>
                                     <Grid container direction='column'>
                                        <img src={ex.imageAWSLink}/>
                                        <button className="edu-btn btn-small btn-secondary" onClick={() => {
                                                downloadPhoto(ex.imageAWSLink[0]);
                                        }}>Download <DownloadRoundedIcon fontSize='large' /></button>
                                        <span><b>Prompt:</b> {ex.prompt.substring(0,100)}...</span>
                                        {selectedExample === ex._id ?
                                            <b style={{color:'#7152E9'}}><CheckIcon/>Prompt Copied</b>
                                            : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(ex._id, ex.prompt)}><ContentCopyIcon/>Copy Prompt</button>}
                                        {user.teams 
                                        && (Object.keys(user.teams).length > 0)
                                        && <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                            {Object.values(user.teams).map(team =>
                                            {return <>
                                            <Checkbox
                                                id={team._id}
                                                size='large'
                                                defaultChecked={ex.team && ex.team[team._id] && ex.team[team._id] === true}
                                                onClick={(e) => addImageToTeam(ex._id, team._id, e)}
                                                color="primary"
                                                /> <span style={{ fontSize: '12px' }}>{team.name}</span></>})
                                        }</div>}
                                    </Grid>
                                    <br/>
                                    </Grid>
                            })}

                            {(!user.createdPhotos || user.createdPhotos.length === 0)
                            && <b>No Photos created yet!!</b>}
                            
                        </Grid>
                    </div>
                                <br/>
                                <br/>
                    {user.totalPhotosGenerated
                    && getpaginationHeader(user.totalPhotosGenerated, fetchUserPhotos, pageNumber, setPageNumber)} 
                </div>
            </LayoutAfterLogin>
    </>
    
  )
}

export default History