import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import OurUSPs from '../how-it-works/OurUSPs';
import ReactPlayer from 'react-player'
import Grid from '@material-ui/core/Grid'
import { useGlobalContext } from '../../context/global_context';

const WhyUs = () => {
    const {
        demoVideo,
        updateDemoVideo,
        isMobile
    } = useGlobalContext();

    return (
        <div className="edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <hr />
                        <SectionTitle
                            classes="text-center"
                            slogan="How To Use InstaPhotoAI?"
                            title="InstaPhotoAI Demo Videos"
                        />
                    </div>
                </div>
                <br/><br/>
                <Grid
                    container
                    direction={isMobile ? "column" : "row"}
                    justifyContent="center"
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12} md={6}>
                        <ReactPlayer muted={true} style={{ border: '2px solid #8c52ff'}} playing controls width={isMobile ? "100%" : "550px"} height={isMobile ? "100%": "309px"} url={demoVideo.url} />
                        <h6>#{demoVideo.index}. {demoVideo.title}</h6>
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <ReactPlayer muted={true} playing={false} style={{ border: demoVideo.index === 1 ? '2px solid #8c52ff' : '2px solid #9592a2'}} light controls width={demoVideo.index === 1 ? '214px' : '200px'} height={demoVideo.index === 1 ? '120px' : '100px'} url='https://youtu.be/Qy_r8yLDWa4' onClickPreview={() => updateDemoVideo(1, "Create any photo in seconds", "https://youtu.be/Qy_r8yLDWa4")} />
                        <b onClick={() => updateDemoVideo(1, "Create any photo in seconds", "https://youtu.be/Qy_r8yLDWa4")} style={{ color: demoVideo.index === 1 ? '#8c52ff' : '#9592a2', cursor: 'pointer' }}>#1. Create any photo in seconds</b>
                        <br/><br/>
                        <ReactPlayer muted={true} playing={false} style={{ border: demoVideo.index === 2 ? '2px solid #8c52ff' : '2px solid #9592a2' }} light controls width={demoVideo.index === 2 ? '214px' : '200px'} height={demoVideo.index === 2 ? '120px' : '100px'} url='https://youtu.be/n50gYoX5r-A' onClickPreview={() => updateDemoVideo(2, "Create a similar face photo", "https://youtu.be/n50gYoX5r-A")} />
                        <b onClick={() => updateDemoVideo(2, "Create a similar face photo", "https://youtu.be/n50gYoX5r-A")} style={{ color: demoVideo.index === 2 ? '#8c52ff' : '#9592a2', cursor: 'pointer' }}>#2. Create a similar face photo</b>
                        <br/><br/>
                        <ReactPlayer muted={true} style={{ border: demoVideo.index === 3 ? '2px solid #8c52ff' : '2px solid #9592a2' }} light controls width={demoVideo.index === 3 ? '214px' : '200px'} height={demoVideo.index === 3 ? '120px' : '100px'} url='https://youtu.be/STwiW2j58CQ' onClickPreview={() => updateDemoVideo(3, "Replace face in an existing photo", "https://youtu.be/STwiW2j58CQ")} />
                        <b onClick={() => updateDemoVideo(3, "Replace face in an existing photo", "https://youtu.be/STwiW2j58CQ")} style={{ color: demoVideo.index === 3 ? '#8c52ff' : '#9592a2', cursor: 'pointer' }}>#3. Replace face in an existing photo</b>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ReactPlayer muted={true} style={{ border: demoVideo.index === 4 ? '2px solid #8c52ff' : '2px solid #9592a2' }} light controls width={demoVideo.index === 4 ? '214px' : '200px'} height={demoVideo.index === 4 ? '120px' : '100px'} url='https://youtu.be/leoyQ_p8I7A' onClickPreview={() => updateDemoVideo(4, "Make a repeated pattern", "https://youtu.be/leoyQ_p8I7A")}/>
                        <b onClick={() => updateDemoVideo(4, "Make a repeated pattern", "https://youtu.be/leoyQ_p8I7A")} style={{ color: demoVideo.index === 4 ? '#8c52ff' : '#9592a2', cursor: 'pointer' }}>#4. Make a repeated pattern</b>
                        <br/><br/>
                        <ReactPlayer muted={true} style={{ border: demoVideo.index === 5 ? '2px solid #8c52ff' : '2px solid #9592a2' }} light controls width={demoVideo.index === 5 ? '214px' : '200px'} height={demoVideo.index === 5 ? '120px' : '100px'} url='https://youtu.be/leJC3uM3bdw' onClickPreview={() => updateDemoVideo(5, "Create photo with reference photo", "https://youtu.be/leJC3uM3bdw")}/>
                        <b onClick={() => updateDemoVideo(5, "Create photo with reference photo", "https://youtu.be/leJC3uM3bdw")} style={{ color: demoVideo.index === 5 ? '#8c52ff' : '#9592a2', cursor: 'pointer' }}>#5. Create photo with reference photo</b>
                        </Grid>
                    
                </Grid>

            </div>
        </div>
    )
}

export default WhyUs;
