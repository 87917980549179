import { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ParallaxProvider } from 'react-scroll-parallax';
import 'dotenv/config';
import { transitions, positions, types, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { GoogleOAuthProvider } from '@react-oauth/google';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    transition: transitions.SCALE,
    zIndex: 99
}

root.render(
    <GoogleOAuthProvider clientId="418380832310-j3oicq46pfsj2c9j3obsd2mr8o03qrtc.apps.googleusercontent.com">
        <Fragment>
            <ParallaxProvider>
                <AlertProvider template={AlertTemplate} {...options}>
                    <App />
                </AlertProvider>
            </ParallaxProvider>
        </Fragment>
    </GoogleOAuthProvider>
);