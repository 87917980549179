import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';
import InstagramIcon from '@mui/icons-material/Instagram';

const FooterOne = () => {

    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={"/"}>
                                            <img className="logo-light" src="/images/logo/logo-white.png" alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    <p className="description">Get Insta Photo today
                                    and use in your Instagram, LinkedIn, Facebook, Blogs and Marketing Campaign. 
                                    Boost your social networking and media by 
                                    AI generated beautiful photos.</p>
                                            <div className="feature-style-3">
                                        <a href="https://www.producthunt.com/products/instaphotoai?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-instaphotoai" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=538286&theme=light" alt="InstaPhotoAI - Photorealistic&#0032;Images&#0032;For&#0032;Your&#0032;Content&#0032;Within&#0032;Seconds | Product Hunt" style={{width:'250px', height: '54px'}} width="250px" height="54px" /></a>
                                        <br/>
                                        <br/>
                                        <a href="https://theresanaiforthat.com/ai/instaphotoai/?ref=featured&v=609429" target="_blank"><img width="300" src="https://media.theresanaiforthat.com/featured4.png"/></a>
                                        </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Explore</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/about-us"><i className="icon-Double-arrow"></i>About Us</Link></li>
                                            <li><Link to="/faq"><i className="icon-Double-arrow"></i>FAQ Question</Link></li>
                                            {/* <li><Link to="/pricing"><i className="icon-Double-arrow"></i>Pricing</Link></li> */}
                                            <li><Link to="/cancellation-refund-policy"><i className="icon-Double-arrow"></i>Cancellation & Refund</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Useful Links</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/contact-us"><i className="icon-Double-arrow"></i>Contact Us</Link></li>
                                            <li><Link to='/terms-and-conditions'><i className="icon-Double-arrow"></i>Terms & Conditions</Link></li>
                                            <li><Link to='/privacy-policy'><i className="icon-Double-arrow"></i>Privacy Policy</Link></li>
                                            <li><Link to='/blogs'><i className="icon-Double-arrow"></i>Blogs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contact Info</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i>1st Block, Jayanagar, Bangalore
                                                </li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: +91 7093003840">+91 7093003840</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:connect@instaphotoai.com">connect@instaphotoai.com</a></li>
                                                <li><a target="_blank" href="https://www.instagram.com/chitra_ai/"><InstagramIcon fontSize="large"/> @chitra_ai</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2023 Insta Photo AI. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;