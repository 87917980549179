import Grid from '@material-ui/core/Grid'

const Blog_3 = () => {
    return (
      <>
      <div>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>I have been using many different AI image generators after the hipe of ChatGPT and realized that image generative AI is quite addictive. I have used great products like midJouney, Stable Diffusion, InstaPhotoAI, LensaAI and Playground AI. Almost all the AI apps generate good results but surely need some practice in writing prompts. It took me around 10 days to create images closer to my expectations. </span></p>
      <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/1/4.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/5.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/6.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/7.png"/></Grid>
      </Grid>
      <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/1/8.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/9.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/10.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/11.png"/></Grid>
              
      </Grid>
      <br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>Why creating prompts is challenging:</span></h1>
      <ul>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>Ambiguous description </b>- Describing an image in a way that captures the intended meaning without ambiguity can be tricky</span></p>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>Limited vocabulary in AI model training </b>- AI models have a predefined vocabulary based on their training data. Generating diverse and creative descriptions may be limited by the vocabulary the model has learned.</span></p>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}>Crafting prompts that spark creativity can be challenging</span></p>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>Experimentation and Iteration</b> - Creating effective prompts often requires experimentation and iteration. It may take several attempts to find the right balance and wording that consistently produces the desired results.</span></p>
        </li>
      </ul>  
      <br />
      <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/1/12.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/13.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/14.png"/></Grid>
              <Grid item xs><img src="/images/blogs/1/15.png"/></Grid>
      </Grid>
      <br />
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>I wanted a profile photo for my LinkedIn profile, thus, I generated the following photo in the initial week of using AI image generators</span></p>
      <div style={{textAlign:'center'}}>
        <img src="/images/blogs/3/1.png" width="400px"/>
        <p>Prompt used - "woman wearing blue business suit"</p>
      </div>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>I was not happy with this image. You can notice that the AI image generator used the prompt but added random additional things which I have not mentioned like background, hairstyle and expression. After spending some 3 weeks in generating many images and practicing different prompts, I was able to learn few hacks for generating stunning expected images. </span></p>
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>Tips to write effective prompts for AI image generator</span></h1>
      <ul>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>Starting your prompt with an Image type</b> - Portrait type or medium is very important instruction for a gen-AI tool. It is basically the look and feel of the portrait. We put the portrait type in the starting so that it get the most importance by the AI tool and brings the output image closer to the expected result. Following are few portrait types which can be used;</span></p>
          <span style={{fontSize: '16pt', fontWeight:'500'}}>1. Oil Painting</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>2. Photograph</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>3. Water color painting</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>4. Airbrush painting</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>5. Pastel chalks</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>6. Digital art</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>7. illustration</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>8. Splash Art and many more.</span>
          <br/><br/>
          <div style={{textAlign:'center'}}>
            <img width="400px" src="/images/blogs/3/2.png"/>
            <p>We put the portrait type in the starting so that it get the most importance by the AI tool and brings the output image closer to the expected result.Prompt used - "Splash art, painting, woman"</p>
          </div>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>End your prompt with the "Stylizers"</b> - Stylizers is an instruction to enhance the portrait for a gen-AI tool. It basically explains the lighting, clarity and style of the image. Following are few stylizers which can be used;</span></p>
          <span style={{fontSize: '16pt', fontWeight:'500'}}>1. Highly detailed</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>2. Ultra realistic</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>3. Soft lighting</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>4. Studio lighting</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>5. Octane render</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>6. Unreal engine</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>7. Trending on artstation</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>8. HD 8k</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>9. 3D blender render</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>10. Dreamcore style</span>
          <br/><br/>
          <div style={{textAlign:'center'}}>
            <img width="400px" src="/images/blogs/3/3.png"/>
          <p>Prompt used - "profile photo, woman, red hair, soft lighting, HD"</p>
          </div>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>Increase word weight with the "Parentheses"</b> - Parentheses are one of the ways to increase the weight of the words in the prompt. It will instruct generative-AI tool on the importance of those words and it try to create portraits accordingly. More number of parentheses shown the importance level.</span></p>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>"Looking into camera" is a magical phrase</b> - If you need a clear portrait where the model is looking straight into the camera then add the phrase "looking into camera" at the end of your prompt. It will give the portrait of the model with a straight pose and as if looking directly at the camera lens.</span></p>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>Adding a camera and focus details</b> - Camera and focus add clarity, lighting and details in the AI portrait. Just by mentioning a specific camera shot, you can get a better AI image. A few examples are;</span></p>
        </li>
        <span style={{fontSize: '16pt', fontWeight:'500'}}>1. DSLR</span>
        <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>2. Fuji XT3</span>
        <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>3. Canon</span>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}>Also, try to give focus details. As most of us are not experts in photography, searching some example shots online and then trial-error different prompts is the way to get to your perfect portrait.</span></p>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>Adding background things/color with the "_____ in the background" phrase </b>- Sometimes even with a good looking AI portrait, you may want a specific kind of background. There are multiple ways to add attractive style to the background. But the most effective way is to use the phrase "in the background". Some example prompts are;</span></p>
          <span style={{fontSize: '16pt', fontWeight:'500'}}>1. model, standing, sea beach in the background</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>2. model, sitting, garden in the background</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>3. model, praying, interior of European architecture church in the background</span>
          <br/><span style={{fontSize: '16pt', fontWeight:'500'}}>4. model, standing, dark background</span>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}>Lastly, <b>adding a particular style with the "Artist name"</b> - Mentioning artist names in the prompt is one of the ways to add a particular style to the generated image. You can add artist's name at the end as - "art by 'artist_name'". For example - if I want to create my portrait as if created by Picasso. My prompt could be - "woman portrait, art by Picasso, HD". Similarly, I can generate portraits as if created by Vincent Van Gogh, Johannes Vermeer, Leonardo Da Vinci and other historian artists.</span></p>
          <div style={{textAlign:'center'}}>
            <img width="400px" src="/images/blogs/3/4.png"/>
          <p>Prompt used - "portrait, woman, art by Picasso, cubical painting"</p>
        </div>
        </li>
        <li>
          <p><span style={{fontSize: '16pt', fontWeight:'500'}}><b>NOTE</b> - avoid using artist name as it may be unethical. I am just showing how to use it by using historian artists. Make sure not to use this feature to generate art from any recent artists.</span></p>
        </li>
      </ul> 
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>After learning on how to write effective prompts, I retried to generating my profile picture for LinkedIn and I able to successful generate below image with 2 trials.</span></p>
      <div style={{textAlign:'center'}}>
        <img src="/images/blogs/3/5.png"/>
      <p>Prompt used is as follows;</p>
      </div>
      <span style={{fontSize: '16pt', fontWeight:'500'}}><i>profile photo, woman, wearing black business suit, happy, confident, white in background, (soft lighting), HD</i></span>
      <br />
      <br />
      <span style={{fontSize: '16pt', fontWeight:'500'}}>I hope this article will help you in writing better prompts for your AI image generation, especially in case of text to image generation.</span>
      <br />


      <br />
      <p style={{fontSize: '16pt', fontWeight:'500'}}>Check Login to <a href="https://instaphotoai.com" target="_blank"><u style={{color:"blue"}}>InstaPhotoAI</u></a> for stunning AI photos.</p>
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>Unleashing the Potential of AI Images and Headshots</span></h1>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>In the contemporary digital landscape, visual content reigns supreme, shaping our online presence profoundly. Leveraging the power of AI-generated images, particularly AI headshots, is pivotal in navigating the competitive social media sphere. In this blog post, we delve into the significance of AI images and explore how they can revolutionize your online brand identity while enhancing social media engagement.</span></p>
      <br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>The Importance of AI Images</span></h1>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>Artificial Intelligence (AI) has transformed various facets of our lives, including image generation. AI image generators provide remarkable capabilities, producing stunning visuals indistinguishable from conventionally captured photos. These AI-generated images not only save time and resources but also unlock endless creative possibilities.</span></p>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>AI images offer unparalleled versatility. Whether you're designing a website, curating marketing materials, or bolstering social media content, AI empowers you to craft tailored visuals that resonate with your audience. Furthermore, AI algorithms can analyze extensive datasets to generate images optimized for maximum engagement and conversion rates.</span></p>
      <br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>The Significance of AI Headshots</span></h1>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>In the realm of professional networking and personal branding, AI headshots hold immense sway. A meticulously crafted AI headshot communicates professionalism, approachability, and credibility, serving as a potent asset for social media profiles, resumes, and professional portfolios.</span></p>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>AI headshots boast several advantages over traditional photoshoots. They eliminate the need for costly photography sessions and protracted editing processes, enabling individuals to acquire polished headshots swiftly and affordably. Moreover, AI technology can enhance facial features, adjust lighting, and eliminate imperfections, ensuring that your headshot presents you in the best possible light.</span></p>
      <br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>The Social Boost of AI Portraits</span></h1>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>In the fiercely competitive landscape of social media, attention is a coveted commodity. High-quality AI portraits possess the power to captivate users, compelling them to engage with your content and delve deeper into your profile. Whether you're a business seeking to attract customers or an individual aiming to expand your network, investing in visually striking AI images can yield substantial returns.</span></p>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>Compelling AI portraits not only command attention but also convey professionalism and authenticity. They showcase your personality, expertise, and brand ethos, fostering deeper connections with your audience. Additionally, captivating visuals are more likely to be shared, amplifying your reach and bolstering your social influence.</span></p>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>In conclusion, AI images and headshots offer a convenient and effective means of enhancing your online presence. By harnessing the potential of AI-generated visuals and prioritizing the quality of your social media images, you can elevate your brand, expand your network, and unlock new opportunities in the digital landscape.</span></p>
      <br/>
      <p style={{fontSize: '16pt', fontWeight:'500'}}>Check Login to <a href="https://instaphotoai.com" target="_blank"><u style={{color:"blue"}}>InstaPhotoAI</u></a> for stunning AI photos.</p>
    </div>
      </>)
}

export default Blog_3;