import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { useGlobalContext } from '../../context/global_context';
import blogs from '../../assets/blogs/blogsInfo.json';
import Blog_1 from '../../assets/blogs/Blog_1';
import Blog_2 from '../../assets/blogs/Blog_2';
import Blog_3 from '../../assets/blogs/Blog_3';
import Blog_4 from '../../assets/blogs/Blog_4';
import Blog_5 from '../../assets/blogs/Blog_5';
import BlogCard from '../../components/blogs/BlogCard';

const Blog = () => {

    const id = (window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)).replaceAll('%20',' ');
    console.log("id: ", id);

    const blog = blogs[id];

    const {
        fetchBlog,
        selectedBlog
    } = useGlobalContext();

    if (!selectedBlog) {
        fetchBlog(id, blogs[id]);
    }

    return (
        <>
            <SEO title="InstaPhotoAI | Blog" />
            <Layout>
                <BreadcrumbOne
                    title="Blog"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blogs"
                />
                <div className="edu-section-gap container edu-pricing-area edu-section-gap bg-image">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan="InstaPhotoAI Blog"
                                title={selectedBlog?.name.toUpperCase()}
                            />
                        </div>
                    </div>
                    <div style={{ padding: '10%' }}>
                        {blog.id == 1 && <Blog_1 />}
                        {blog.id == 2 && <Blog_2 />}
                        {blog.id == 3 && <Blog_3 />}
                        {blog.id == 4 && <Blog_4 />}
                        {blog.id == 5 && <Blog_5 />}

                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes="text-center"
                            slogan="Interested in learning more?"
                            title="Check Other Blogs From InstaPhotoAI"
                        />
                    </div>
                </div>
                <BlogCard />
            </Layout>
        </>
    )
}

export default Blog;