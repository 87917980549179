import React from 'react';
import { Link } from 'react-router-dom';

const AboutFive = ( { wrapperClass } ) => {

    return (
        <div className={`home-three-about edu-about-area about-style-4 bg-color-white ${ wrapperClass || 'edu-section-gapBottom' }`}>
            <div className="container eduvibe-animated-shape">
                <div className="row g-lg-5 g-md-5 g-sm-5">
                    
                    <div className="col-lg-12 col-xl-6">
                        <div className="inner mt_mobile--40">
                            <div className="section-title text-start">
                                <span className="pre-title">Who We Are</span>
                                <h3 className="title">We Provide</h3>
                                <h3 className="title">Insta Stunning Photo</h3>
                                <h3 className="title">By AI</h3>
                            </div>
                            <div className="feature-style-6 mt--40">
                                <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Save Photographer Cost</h6>
                                        <p className="description">No need to get photographers for getting photos for your blogs, instagram, linkedIn, tinder, or just for fun.</p>
                                    </div>
                                </div>

                                <div className="edu-feature-list color-variation-2">
                                    <div className="icon">
                                        <img src="/images/about/about-05/book.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">No Copyright Issues</h6>
                                        <p className="description">Every photo is AI generated and thus unique. You will have every right on your generated photo.</p>
                                    </div>
                                </div>
                                
                                <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Beautiful Photos</h6>
                                        <p className="description">We trained our AI to produce beautiful image eachtime, everytime.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="read-more-btn mt--30">
                            <Link className="edu-btn" to="/">Explore More<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                        <br/>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                        <div className="gallery-wrapper">
                            <img src="/images/about/about-05/about-us.png" alt="About Images" />
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-27.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;