import React from 'react';
import { useGlobalContext } from '../../context/global_context';
import { useAlert } from 'react-alert';

import blogs from '../../assets/blogs/blogsInfo.json';

const BlogCard = () => {
    const alert = useAlert()

    const {
        isMobile,
        openBlog
    } = useGlobalContext();

    return (
        <div className="edu-section-gap container edu-pricing-area edu-section-gap bg-image">
            {isMobile ?
                <>
                    {Object.values(blogs).map((data, index) => (
                        <div className={`mobile-blog-table`} onClick={() => openBlog({ id: data.id, blogInformation: data})}>
                            <div className="row" key={index}>
                                <div className="col-5" >
                                    <img src={data.headingImage} width="128px" height="128px" />
                                </div>
                                <div className="col-7" >
                                    <div className="blog-header">
                                        <h1 className="title">{data.name}</h1>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="description">{data.contentPrefix.substring(0, 100) + '...'}</span>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-12" >
                                    <button className={`edu-btn btn-small ${data.active !== true ? ' btn-dark' : ''}`} style={{ width: '100%' }} onClick={() => openBlog({ id: data.id, blogInformation: data})}>
                                        Read More<i className="icon-arrow-right-line-right"></i>
                                    </button>
                                </div>
                            </div>

                        </div>))
                    }
                </>
                : <div className="row g-5">
                    {Object.values(blogs).map((data, index) => (
                        <div className="col-lg-6 col-md-6 col-12" style={{'cursor': 'pointer'}} key={index} onClick={() => openBlog({ id: data.id, blogInformation: data})}>
                            <div className="row blog-table">
                            <div className="col-6" >
                                    <img src={data.headingImage} width="256px" height="256px" />
                            </div>
                            <div className={`col-6`}>
                                <div className="blog-header">
                                    <h1 className="title">{data.name}</h1>
                                    <br />
                                    <div className="price-wrap">
                                        <div className="yearly-pricing price-list">
                                            <span className="description">{data.contentPrefix.substring(0, 150) + '...'}</span>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing-btn">
                                    <button className={`edu-btn ${data.active !== true ? ' btn-dark' : ''}`} onClick={() => openBlog({ id: data.id, blogInformation: data})}>
                                        Read More<i className="icon-arrow-right-line-right"></i>
                                    </button>
                                </div>
                            </div>
                            </div>

                        </div>
                    ))
                    }
                </div>}
        </div>
    )
}

export default BlogCard;