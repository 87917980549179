import React, {useState} from 'react';
import { useGlobalContext } from '../../context/global_context';
import { useAlert } from 'react-alert';
import SectionTitle from '../sectionTitle/SectionTitle';
import { useNavigate } from 'react-router-dom';

const CustomImages = ( ) => {
    const alert = useAlert()
    const navigate = useNavigate();

    const [email, setEmail] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [note, setNote] = useState(undefined);
    
    const {
        sendBusinessEmail,
    } = useGlobalContext();

    return (
        <div id="business" className="edu-section-gap container edu-pricing-area edu-section-gap bg-image">
            <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "Need Custom Images for your Business? <br/> Contact Us!!"
                            title = "<a target='_blank' href='mailto:connect@instaphotoai.com'>connect@instaphotoai.com</a>"
                        />
                        <div className='pricing-header'> 
                            <p className='title'>Provide your emailId if you what us to contact you.</p> 
                            <input style={{ width: '400px' }} type='text' placeholder='Your Full Name' onChange={e => {setName(e.target.value)}} value={name} /> 
                            <br/>   
                            <br/>   
                            <input style={{ width: '400px' }} type='email' placeholder='Your Business EmailId' onChange={e => {setEmail(e.target.value)}} value={email} /> 
                            <br/>
                            <br/>
                            <textarea style={{ width: '400px' }} multiple rows={3} type='text' placeholder='Write your message..' onChange={e => {setNote(e.target.value)}} value={note} /> 
                            <br/>
                            <br/>
                            <button className='edu-btn btn-small' onClick={() => { sendBusinessEmail({name, email, note}); navigate("/thanks-business")}}>Submit</button>
                        </div>
                    </div>
                </div>
                </div>
    )
}

export default CustomImages;