const BACKEND_BASE_URL = "https://thethrivingcompany.com"
// const BACKEND_BASE_URL = "http://localhost:8800"

const backendCall = async({method, path, body, token, admin, files, filesKey}) => {
    const url = BACKEND_BASE_URL + path;
    var form = undefined;

    if (files && files.length > 0 && filesKey) {
        form = new FormData();
        for (let i = 0; i < files.length; i++) {
            form.append(filesKey, files[i], files[i].name);
        }
    }

    const params = {
        "method": method,
        "mode": "cors",
        "signal": AbortSignal.timeout( 900000 ),
        "headers": {
            "Content-Type": "application/json",
            "x-auth": token,
            "admin-key": admin ? "RiseAsTheSun" : "",
            "access-control-allow-origin" : "*",
            'Cache-Control': 'no-cache'
        }
    };

    if(body) {
        params.body = JSON.stringify(body);
    } else if (form) {
        // Generate a random boundary string
        const boundary = '-------------------------' + Math.random().toString(36).substring(2);

        let formBody = '';
        form.forEach((value, key) => {
            formBody += '--' + boundary + '\r\n';
            formBody += 'Content-Disposition: form-data; name="' + key + '"\r\n\r\n';
            formBody += value + '\r\n';
        });
        formBody += '--' + boundary + '--\r\n';
        
        params.headers["Content-Type"] = "multipart/form-data; boundary=" + boundary;
        params.body = formBody
    }

    try{
        const response = await fetch(url, params);
        const responseJSON = await response.json();
        if(response.status == 200) {
            return responseJSON;
        } else {
            return {
                ...responseJSON,
                status: response.status
            } 
        }
    } catch(error) {
        return {
            success: false,
            error
        }
    }
}

export {backendCall}