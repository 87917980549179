const Announcement = () => {
    return (
        <>
            <div style={{fontWeight:'bold', fontSize:'16px', minHeight:'100px', backgroundColor:'#7152E9', color:'#fff'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <img src="./images/banner/banner-04/app_man.png" width="100%"/>
                        </div>
                        <div className="col-lg-4" style={{alignSelf:'center'}}>
                            <span style={{fontWeight:'bold', fontSize:'16px', minHeight:'100px', backgroundColor:'#7152E9', color:'#fff'}}>Try our 1st Mobile App</span>
                            <br/>
                            <span style={{fontWeight:'bold', fontSize:'24px', minHeight:'100px', backgroundColor:'#7152E9', color:'#fff'}}>InstaPortraitAI: Free AI Images</span>
                            <a href="https://play.google.com/store/apps/details?id=com.thethrivingcompany.aiportrait" target="_blank"><button className="edu-btn btn-small btn-secondary">Android Play Store</button></ a>
                        </div>
                        <div className="col-lg-4">
                            <img src="./images/banner/banner-04/app_woman.png" width="100%"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Announcement;