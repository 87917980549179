import React from 'react';
import Layout from '../../common/Layout';
import { useGlobalContext } from '../../context/global_context';
import Grid from '@material-ui/core/Grid'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

const formatXAxis = (tickItem) => {
    // Format the tick item here (e.g., convert the timestamp to a readable format)
    const date = new Date(tickItem);
    return date.toLocaleTimeString();
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label).toLocaleString();
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Time: ${date}`}</p>
          <p>{`Value: ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };
  
  const CustomizedDot = (props) => {
    const { cx, cy, value } = props;
  
    if (value > 10) { // Customize the condition as per your data
      return (
        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
          <path d="M512 0C229.2288 0 0 229.2288 0 512s229.2288 512 512 512 512-229.2288 512-512S794.7712 0 512 0z m0 921.6c-225.7408 0-409.6-183.8592-409.6-409.6S286.2592 102.4 512 102.4 921.6 286.2592 921.6 512 737.7408 921.6 512 921.6z"/>
        </svg>
      );
    }
  
    return <Dot {...props} />;
  };

const BITSAdmin = () => {
    const {
        admin,
        loadReportType,
        setAdminStartDate,
        setAdminEndDate,
        submitReportsRequest
    } = useGlobalContext();

    let totalnoOfNewlyRegisteredUsers = 0;
    let totalnoOfGeneratedImages = 0;
    
    if(admin.reportData) {
        totalnoOfNewlyRegisteredUsers = admin.reportData.reduce((total, entry) => total + entry.noOfNewlyRegisteredUsers, 0);
        totalnoOfGeneratedImages = admin.reportData.reduce((total, entry) => total + entry.noOfGeneratedImages, 0);
    }

    return (
        <>
            <Layout>
            <div style={{ width: '100%', margin: '5%' }}>
                
                    <>
                    Start Date: 
                    <Flatpickr
                        data-enable-time
                        value={admin.startDate}
                        onChange={(selectedDates) => setAdminStartDate(selectedDates[0])}
                        options={{ dateFormat: 'Y-m-d H:i' }}
                        style={{width: '20%'}}
                    />
     
                        &nbsp;&nbsp;
                    End Date: 
                    <Flatpickr
                        data-enable-time
                        value={admin.endDate}
                        onChange={(selectedDates) => setAdminEndDate(selectedDates[0])}
                        options={{ dateFormat: 'Y-m-d H:i' }}
                        style={{width: '20%'}}
                    />
                        &nbsp;&nbsp;
                        <select style={{width: '20%'}} onChange={(e) => loadReportType(e)} defaultValue="hourly">
                                            <option value="hourly">Hourly</option>
                                            <option value="daily">Daily</option>
                                        </select>
                                        &nbsp;&nbsp;
                        <button className='edu-btn btn-secondary btn-small' onClick={() => submitReportsRequest("BITS")}>Ok</button>&nbsp;&nbsp;
                          <br/>
                    <br/>
                    {admin.reportData !== undefined && 
                    <Grid container direction='row'>
                    <Grid item>
                        <b>Total no. of Users</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfTotalUsers" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of new users registered: {totalnoOfNewlyRegisteredUsers}</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfNewlyRegisteredUsers" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Total no. of PRO Users</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfProUsers" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Total no. of PRO Paid Users</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfProPaidUsers" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of images generated: {totalnoOfGeneratedImages}</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfGeneratedImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of images Feed published</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfFeedsPublishedImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of images Marketplace published</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfMarketplacePublishedImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of images Competition published</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfCompetitionPublishedImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of images liked</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfLikes" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of comments</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfComments" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Number of active users(generated images)</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfActiveUsersInGenerateImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Number of active users(feeds published images)</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfActiveUsersInFeedsPublishImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Number of active users(marketplace published images)</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfActiveUsersInMarketplacePublishImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Number of active users(liked othes images)</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfActiveUsersInLikeImages" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Number of active users(comments images)</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfActiveUsersInComments" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Number of active users(messages)</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfActiveUsersInMessage" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>Number of messages sent</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfMessagesSent" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of Simple Invitation Added</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfSimpleInvitedUsers" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    <Grid item>
                        <b>No. of Special Invitation Added</b>
                        <LineChart width={600} height={500} data={admin.reportData}>
                            <Line type="monotone" dataKey="noOfSpecialInvitedUsers" stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />}  />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                            <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                        </LineChart>
                    </Grid>
                    
                    </Grid>
                    }

                    </>
                </div>
            </Layout>
        </>
    )
}

export default BITSAdmin;