import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

import { useGlobalContext } from '../../context/global_context';

const DeleteUser = () => {
    const {
        updateRegisterEmail,
        deleteUser
    } = useGlobalContext();

    const [deleted, setDeleted] = useState(false);

    return (
        <>
            <SEO title="Delete User" />
            <Layout>
                <BreadcrumbOne
                    title="Delete User"
                />
                <div className="edu-section-gap container edu-pricing-area edu-section-gap bg-image">
                    <div className={`mobile-pricing-table`}>
                        <div className="pricing-header">
                            <h3 className="title">Provide emailId to delete user from RainForest AI</h3>
                            {!deleted ? <><input style={{ width: "200px" }} type="email" placeholder="Email" onChange={updateRegisterEmail} />
                                <br />
                                <br />
                                <button className='edu-btn btn-small' onClick={() => {setDeleted(true); deleteUser();}}>Delete</button></>
                                : <p style={{color:'green'}}> User Deleted
                                </p>}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default DeleteUser;