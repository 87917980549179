import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import { useGlobalContext } from '../../context/global_context';

const HomeFourAbout = () => {
    const {
        user,
        setNeedLogin
    } = useGlobalContext();

    return (
        <div className="banner-style-4 edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row row--50">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="">
                                <img className="image-1" src="/images/banner/banner-04/5.png" alt="About Images" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner mt_md--40 mt_sm--40">
                            <SectionTitle
                                classes = "text-start"
                                slogan = "Increase engagement With"
                                title = "High Quality Realistic Photos"
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <p className="description mt--40 mt_md--20 mt_sm--20">Photos are required for stunning website, blogs, instagram engagement and product marketing. We Provide ultra HD images with just 6-20 initial images.</p>
                                <h6 className="subtitle mb--20">Used For</h6>
                            </ScrollAnimation>

                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="about-feature-list"
                                animateOnce={true}
                            >
                                <div className="row g-5">
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">Instagram</h6>
                                                <p className="feature-description">Increase profile engagement by 30%.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                        <div className="feature-content">
                                                <h6 className="feature-title">Online Store</h6>
                                                <p className="feature-description">Increase customer conversion by 50%.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="row g-5">
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">Blogs</h6>
                                                <p className="feature-description">Increase readability by 40%.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                        <div className="feature-content">
                                                <h6 className="feature-title">Brochures</h6>
                                                <p className="feature-description">Print beautiful brochures for your clients with stunning photos.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn mt--40"
                                animateOnce={true}
                            >
                                <button className={`edu-btn btn-medium btn-secondary left-icon left-btn rn-btn`}
                                        onClick={() => setNeedLogin(true)}>
                                        Login <i className="icon-arrow-right-line-right"></i>
                                    </button>
                            </ScrollAnimation>
                            
                        </div>
                    </div>  
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFourAbout;