import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'


import { useGlobalContext } from '../../context/global_context';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Photo from '../photos/Photo';
import PullToRefresh from 'react-simple-pull-to-refresh';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


async function downloadBinaryPhoto(imageBinary) {
    try {
        // Create a temporary anchor element
        // const url = window.URL.createObjectURL("data:image/png;base64," + imageBinary);
        const link = document.createElement('a');
        link.href = "data:image/png;base64," + imageBinary;

        // Extract the filename from the URL
        const filename = "instaPhotoAI_output_" + Date.now() + ".png";

        // Set the download attribute and filename
        link.setAttribute('download', filename);
        document.body.appendChild(link);

        // Simulate a click on the anchor element to start the download
        link.click();

        // Clean up the temporary anchor element
        link.parentNode.removeChild(link);
    } catch (ex) {
        console.log("Error in downloading an image", ex.message)
    }
};

function Candidate() {
    const {
        user,
        logout,
        setLoader,
        fetchUser,
        log,
        fetchUserPhotos
    } = useGlobalContext();

    let followersCount = 0;
    if (user.followers && Object.keys(user.followers).length > 0 && (!user.accountType || user.accountType == "public")) {
        followersCount = Object.values(user.followers).filter(f =>
            f.status !== 'rejected'
        ).length
    } else if (user.followers && Object.keys(user.followers).length > 0 && user.accountType == "private") {
        followersCount = Object.values(user.followers).filter(f =>
            f.status === 'accepted'
        ).length
    }

    let pendingFollowerRequests = [];
    if (user.followers && Object.keys(user.followers).length > 0 && user.accountType == "private") {
        pendingFollowerRequests = Object.values(user.followers).filter((request) =>
            {return request.status === 'requested';}
        );
    }

    const handleRefresh = async() => {
        setLoader(true);
        fetchUser();
        setLoader(false);
    }

    const handleCopyPrompt = (id, prompt) => {
        navigator.clipboard.writeText(prompt);
        setSelectedExample(id)
    }

    const [selectedExample, setSelectedExample] = useState("");

    return (

        <PullToRefresh 
                onRefresh={handleRefresh} 
                pullDownThreshold='10'
                backgroundColor='#fff'
                refreshingContent={<b style={{color:'blue'}}>Reloading...</b>}
                >
        <div className="login-form-box" style={{minWidth:'300px'}}>
            <div className="profile-form">

                <div className="input-box mb--20">
                    <label class="form-check-label">Email:</label>
                    <text type="email" id="email">  {user.email}</text>
                </div>
                <div className="input-box mb--20">
                    <label class="form-check-label">Photos Generated:</label>
                    <text type="email" id="email">{user.totalPhotosGenerated ? user.totalPhotosGenerated : 0}</text>
                </div>
                <div className="input-box mb--20">
                    <label class="form-check-label">PhotoCredits:</label>
                    <text type="email" id="email">  {user.membership?.photosCredit}</text>
                </div>
                <div className="input-box mb--20">
                    <label class="form-check-label">Membership:</label>
                    <text type="text">  {user.membership?.type}</text>
                </div>
                <div className="input-box mb--20">
                <Link to="/pricing">
                    <Button className="edu-btn btn-small btn-secondary"> Check Pricing</Button>
                </Link>
                </div>
                <div className="input-box mb--20">
                    <Button onClick={logout} className="edu-btn btn-small btn-alt btn-white" >Logout</Button>
                </div>
                {user.createdPhotos &&
                <div>
                <label class="form-check-label">Recently Created AI visuals:</label>
                <br/>
                <br/>
                <Grid
                container
                direction="row"
                
                spacing={1}>
                {user.createdPhotos.map((photo, index) => {
                    return <Grid  item xs={6} md={3} style={{border:'1px solid #6F6B80'}}><img src={photo.imageAWSLink[0]} key={index}></img> <span><b>Prompt:</b> {photo.prompt}</span>
                    <br/>
                    {selectedExample === photo._id ?
                        <b style={{color:'#7152E9'}}><CheckIcon/>Prompt Copied</b>
                        : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(photo._id, photo.prompt)}><ContentCopyIcon/>Copy Prompt</button>}</Grid>
                })}
                </Grid>
                </div>}

            </div>
        </div>
        </PullToRefresh>
    )
}

export default Candidate