import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const CancellationRefundPolicy = () => {
    return (
        <>
            <SEO title="Cancellation Refund Policy" />
            <Layout>
                <BreadcrumbOne 
                    title="Cancellation & Refund Policy"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Cancellation & Refund Policy"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <h4 className="title">Cancellation Policy</h4>
                                <p>We try our best to bring fun and beauty to photo creation task. Still if any customer is not very happy with our service, we can't automatically cancel the order. If there is any issue, customer can connect us by calling on +91-7093003840 and we will figure out how to fix things.</p>
            
                                <h4 className="title">Refund Policy</h4>
                                <p>If a customer is not very happy we can refund a percentage of the cost, calculated using the number of days left for subscription duration. Minimum 10% will be deducted as the bank transition charges.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default CancellationRefundPolicy;