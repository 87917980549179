import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import { useGlobalContext } from '../../context/global_context';

const AIPhotoCreation = () => {
    const {
        user,
        setNeedLogin
    } = useGlobalContext();

    return (
        <div className=" eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row row--50">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="">
                                <img className="image-1" src="/images/banner/banner-04/4.png" alt="About Images" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1" >
                    </div>
                    <div className="col-lg-5">
                        <div className="inner mt_md--40 mt_sm--40">
                            <SectionTitle
                                classes = "text-start"
                                slogan = "Explain The Photo &"
                                title = "Create Any AI Generated Photo In Seconds"
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <p className="description mt--40 mt_md--20 mt_sm--20">Make any AI photographs. Indeed, even utilize a reference picture. No need for a human model. Experiment with landscape, clothes, background, expressions, shots and lighting.</p>
                            </ScrollAnimation>

                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn mt--40"
                                animateOnce={true}
                            >
                                <button className={`edu-btn btn-medium btn-secondary left-icon left-btn rn-btn`}
                                        onClick={() => setNeedLogin(true)}>
                                        Signup & Get 10 Free Photos <i className="icon-arrow-right-line-right"></i>
                                    </button>
                                    <br/>
                                    <p className="mt--40 mt_md--20 mt_sm--20" style={{fontSize:'13px'}}>*No credit card required.</p>
                                
                            </ScrollAnimation>

                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AIPhotoCreation;