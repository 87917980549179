import React, {useState} from 'react'
import SEO from '../../common/SEO';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useGlobalContext } from '../../context/global_context';
import Grid from '@material-ui/core/Grid'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

async function downloadPhoto(imageUrl) {
    console.log("imageUrl: ", imageUrl);

    fetch(imageUrl, {
        "mode": "cors",
        "headers": {
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
            'Cache-Control': 'no-cache'
        }
    })
        .then(response => response.blob())
        .then(blob => {
            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // Extract the filename from the URL
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

            // Set the download attribute and filename
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            // Simulate a click on the anchor element to start the download
            link.click();

            // Clean up the temporary anchor element
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading image:', error);
        });
};

const getpaginationHeader = (itemsCount, fetchTeamDetails, pageNumber, setPageNumber, teamId) => {
    const array = []
    for (let i = 0; i < itemsCount/20; i++) {
        array.push(i);
    }
    return <>
    {array.map(a => {
        return <button className={(pageNumber === a) ? 'edu-btn btn-small' : ''} onClick={() => {fetchTeamDetails(teamId, a); setPageNumber(a)}}>{(a + 1)}</button>
    })}
    <br/>
    <br/>

    </>
}

function Teams() {
    const [selectedExample, setSelectedExample] = useState("");
    const [createTeamOpen, setCreateTeamOpen] = useState(false);
    const [editTeamOpen, setEditTeamOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);

    const {
        user,
        path,
        updatePath,
        updatedActiveMenu,
        teams,
        updateSelectedTeam,
        selectedTeam,
        updateNewTeamName,
        updateTeamMembers,
        newTeam,
        createTeam,
        editTeam,
        fetchTeamDetails
    } = useGlobalContext();

    if (path != '/instaphotoai-teams') {
        updatePath('/instaphotoai-teams');
        updatedActiveMenu('none')
    }

    const handleCopyPrompt = (id, prompt) => {
        navigator.clipboard.writeText(prompt);
        setSelectedExample(id)
    }
  return (
    <>
        <SEO title="InstaPhotoAI" />
            <LayoutAfterLogin>
                <BreadcrumbOne 
                    title="Team Gallery"
                    parentUrl="Home"
                    currentUrl="Team Gallery"
                />
                <div className="login-register-page-wrapper edu-section-gap bg-color-white">
                    
                    <div className="container checkout-page-style">
                        {!user.teams
                            && <b>You are not part of any team yet.<br/></b>}

                        <button className={createTeamOpen ? 'btn-small edu-btn' : 'btn-small edu-btn btn-secondary'} onClick={() => setCreateTeamOpen(!createTeamOpen)}>{!createTeamOpen ? "Create Your Team" : "Hide Create Team"}</button>
                        {createTeamOpen
                        && <div style={{ border: '1px solid #e0e0e0', padding:"4px"}}>
                            {user.membership && user.membership.type === "FREE"
                            && <span style={{color:"blue"}}>**You will be able to access only the latest 20 images. <a href="/pricing" target="_blank" ><b><u>Become a Pro</u></b></a> to access unlimited images.<br/><br/></span>}
                            <b>1. Team name:</b>
                            <input type="text" placeholder="example - Team_Chitra" value={newTeam?.name} onChange={updateNewTeamName} />
                            <b>2. Team Members:</b>
                            <br/>
                            <span>Add one member in one line, in the format - member_email,role</span>
                            <br/><span>Roles are - <br/>i. "admin" who can add any new member, submit creations and can see others' creations<br/>ii. "moderator" who can submit creatives and can see other users creations<br/>iii. "creator" who can only submit creations. </span>
                            <textarea type="text" multiple rows={2} placeholder={"jack@email.com,admin\nrose@gmail.com,moderator"} value={newTeam?.members} onChange={updateTeamMembers} />
                            {newTeam && newTeam.error && <span style={{color: "red"}}>{newTeam.error}<br/></span>}
                            <button className='btn-small edu-btn btn-secondary' onClick={createTeam}>Create Your Team</button>
                            
                        </div>
                        }
                        {user.teams 
                        && (Object.values(user.teams).length > 0)
                        && <>
                            <b><br/><br/>Show Gallery Of Team:</b>
                            {
                                <select onChange={updateSelectedTeam} value={selectedTeam}>
                                        <option value='none'>None</option>
                                        {Object.values(user.teams).map(team => {
                                            if(team.role === "admin" || team.role === "moderator") {
                                                return <option value={team._id}>{team.name} ({team.role})</option>
                                            }
                                           
                                        })
                                    }
                                </select>
                            }
                        </>}
                        <br/>
                        <br/>
                        
                        {selectedTeam
                        && teams[selectedTeam]
                        && teams[selectedTeam].role === "admin"
                        && 
                        <>
                        {editTeamOpen
                            ? <>
                            <button className="edu-btn btn-small" onClick={() => setEditTeamOpen(false)}>Hide Team Details</button><br/>
                            <b>Team Members:</b>
                            <br/>
                            <span>Add one member in one line, in the format - member_email,role</span>
                            <br/><span>Roles are - <br/>i. "admin" who can add any new member, submit creations and can see others' creations<br/>ii. "moderator" who can submit creatives and can see other users creations<br/>iii. "creator" who can only submit creations. </span>
                            <textarea type="text" multiple rows={2} defaultValue={teams[selectedTeam].members} onChange={updateTeamMembers} />
                            {newTeam && newTeam.error && <span style={{color: "red"}}>{newTeam.error}<br/></span>}
                            <button className='btn-small edu-btn btn-secondary' onClick={editTeam}>Edit Team</button><br/>
                            </>
                            : <><button className="edu-btn btn-small" onClick={() => setEditTeamOpen(true)}>Show Team Details</button><br/></>
                        }
                        <br/></>
                        }

                        {teams 
                            && teams[selectedTeam]
                            && teams[selectedTeam].photosCount > 0
                    && getpaginationHeader(teams[selectedTeam].photosCount, fetchTeamDetails, pageNumber, setPageNumber, selectedTeam)}

                        <Grid container direction='row' 
                        spacing={1}>
                            
                            {teams 
                            && teams[selectedTeam]
                            && teams[selectedTeam].photos
                            && teams[selectedTeam].photos.map((ex,i) => {
                                return <Grid key={ex._id} item xs={6} md={3} style={{border:'1px solid #6F6B80'}}>
                                     <Grid container direction='column'>
                                        <img src={ex.imageAWSLink}/>
                                        <button className="edu-btn btn-small btn-secondary" onClick={() => {
                                                downloadPhoto(ex.imageAWSLink[0]);
                                        }}>Download <DownloadRoundedIcon fontSize='large' /></button>
                                        <span><b>Prompt:</b> {ex.prompt.substring(0,100)}...</span>
                                        {selectedExample === ex._id ?
                                            <b style={{color:'#7152E9'}}><CheckIcon/>Prompt Copied</b>
                                            : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(ex._id, ex.prompt)}><ContentCopyIcon/>Copy Prompt</button>}
                                            
                                    </Grid>
                                    <br/>
                                    </Grid>
                            })}
                        </Grid>
                            <br/>
                            <br/>
                        {teams 
                            && teams[selectedTeam]
                            && teams[selectedTeam].photosCount > 0
                    && getpaginationHeader(teams[selectedTeam].photosCount, fetchTeamDetails, pageNumber, setPageNumber, selectedTeam)}

                    </div>
                </div>
            </LayoutAfterLogin>
    </>
    
  )
}

export default Teams