import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import GridLoader from "react-spinners/GridLoader";
import Checkbox from '@mui/material/Checkbox';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import Slider from '@mui/material/Slider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import PhotoIcon from '@mui/icons-material/Photo';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GridViewIcon from '@mui/icons-material/GridView';
import WindowIcon from '@mui/icons-material/Window';
import StarIcon from '@mui/icons-material/Star';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorIcon from '@mui/icons-material/Error';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

import {
    Link
} from "react-router-dom";
import { useGlobalContext } from '../../context/global_context';

const useStyles = makeStyles({
    sidePanelWrapper: {
        position: 'fixed',
        top: 70,
        bottom: 0,
        minWidth: '11.9%',
        maxWidth: '11.9%',
        boxShadow: '2px 2px 2px 2px #e6e6e6',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        flexWrap: 'wrap',
        padding: '15px',
    },
    sideBtn: {
        fontSize: '14px',
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bottomBtn: {
        fontSize: '10px',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    mobileBottomWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        boxShadow: '2px 2px 2px 2px #e6e6e6',
        top: "'" + (window.innerHeight - 60) + "px'",
        bottom: '1px',
        minHeight: '60px',
        maxHeight: '60px',
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        zIndex: 10,
        backgroundColor: '#f1f1f1'
    },
    innerDiv: {
        boxShadow: '0 0 2px 2px #d6d6d6',
        padding: '8px',
        color: '#000'
    }
});

const CustomSliderStyles = {
    '& .MuiSlider-markLabel': {
        fontSize: "10px",
        fontWeight: 'bold'
    }
};

const SidePanel = () => {
    const classes = useStyles();
    const [ok, setOk] = useState(true);
    const [paidUserDialog, setPaidUserDialog] = useState(false);

    const [faceCreateAdvanceOptions, setFaceCreateAdvanceOptions] = useState(false);
    const [anyAIPhotoAdvanceOptions, setAnyAIPhotoAdvanceOptions] = useState(false);

    const [selectedExample, setSelectedExample] = useState("");

    const handleCopyPrompt = (id, prompt) => {
        navigator.clipboard.writeText(prompt);
        setSelectedExample(id)
    }

    const {
        user,
        isMobile,
        activeMenu,
        fetchUser,
        updatedActiveMenu,
        demo,
        loading,
        uploads,
        setRecentUploadsDisplay,
        selectUploadedImage,
        selectedPhoto,
        onFaceImageUploaded,
        removeFacePhoto,
        updateMoreChecked,
        onBaseImageUploaded,
        removeBasePhoto,
        generateReplacedPhoto,
        updateDemoPrompt,
        updateAspectRatio,
        updateDemoWidth,
        updateDemoHeight,
        generateNewPhotos,
        onReferenceImageUploaded,
        removeReferencePhoto,
        denoisingStrengthLabel,
        onDenoisingStrengthChange,
        onTilingSelection,
        generateAnyAIPhotos,
        logout,
        fetchExamples,
        themes,
        selectedTheme,
        updateSelectedTheme,
        fetchThemes,
        randomPrompts,
        onFaceLocationChanged,
        isNsfw,
        fetchRandomPrompts,
        examples
    } = useGlobalContext();

    if (examples.length === 0) {
        fetchExamples();
    }

    let pendingFollowerRequests = [];
    if (user.followers && Object.keys(user.followers).length > 0 && user.accountType == "private") {
        pendingFollowerRequests = Object.values(user.followers).filter((request) => { return request.status === 'requested'; }
        );
    }

    const handleThemeClick = (theme) => {
        if (theme && theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0) {
            setPaidUserDialog(true);
        } else {
            updateSelectedTheme(theme)
        }
    }

    return (<>
        <div className={(isMobile == false) ? classes.sidePanelWrapper : classes.mobileBottomWrapper}>
            <Grid
                container
                direction={(isMobile == false) ? "column" : "row"}
                justifyContent="center"
                alignItems='center'
                spacing={0}
            >
                <Grid item>
                    <Link to="/ai-photos" style={{ textDecoration: activeMenu === 'ai-photos' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('ai-photos'); fetchUser(); fetchThemes("anyAIPhoto"); fetchRandomPrompts("anyAIPhoto"); }}>
                        <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'ai-photos' ? <PhotoIcon fontSize='large' /> : <PhotoOutlinedIcon fontSize='large' />}>
                            {user.membership
                                && (user.membership.photosCredit > 0)
                                && <span style={{ color: 'red', fontSize: '8px' }}>{user.membership.photosCredit}</span>}
                        </Button>
                        <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>AI Photo</p>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="/ai-portrait" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('ai-portrait'); fetchUser(); fetchThemes("instaportraitai"); fetchRandomPrompts("instaportraitai"); }}>
                        <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'ai-portrait' ? <AccountBoxIcon fontSize='large' /> : <AccountBoxOutlinedIcon fontSize='large' />}>
                            <span style={{ color: 'red', fontSize: '8px' }}>{user.membership ? user.membership.photosCredit : 0}</span>
                        </Button>
                        <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>AI Portraits</p>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="/face-replacement" style={{ textDecoration: activeMenu === 'face-replacement' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('face-replacement'); fetchUser(); }}>
                        <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'face-replacement' ? <PeopleIcon fontSize='large' /> : <PeopleOutlinedIcon fontSize='large' />}>
                            {user.membership
                                && (user.membership.photosCredit > 0)
                                && <span style={{ color: 'red', fontSize: '8px' }}>{user.membership.photosCredit}</span>}
                        </Button>
                        <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>Face Replace</p>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="/ai-photo-examples" style={{ textDecoration: activeMenu === 'ai-photo-examples' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('ai-photo-examples'); fetchExamples(); }}>
                        <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'ai-photo-examples' ? <WindowIcon fontSize='large' /> : <GridViewIcon fontSize='large' />}>
                        </Button>
                        <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>Examples</p>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="" style={{ textDecoration: activeMenu === 'more' ? 'underline' : 'none' }} >
                        <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" onClick={() => { updatedActiveMenu('more');}} startIcon={<MoreVertIcon fontSize='large' />}>
                        </Button>
                        <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>More</p>
                    </Link>
                </Grid>
            </Grid>
        </div>

            {isMobile && <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={activeMenu === 'ai-portrait'} onClose={() => updatedActiveMenu('none')}>
            <DialogTitle><b className='title' style={{ color: 'blue' }}>AI Portrait</b></DialogTitle>
            <DialogContent>
                <div className={loading ? 'parentDisable' : ''} width="100%">
                    <div className='overlay-box'>
                        <GridLoader
                            color='#cc01ff'
                            loading={loading}
                            size={20} />
                    </div>
                </div>
                <div>
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>1/5. Explain Expected Photo</b>
                        <br />
                        <span style={{ fontSize: '12px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => updateDemoPrompt(undefined, "sidePanelAIPortrait", randomPrompts.instaportraitai[Math.floor(Math.random() * randomPrompts.instaportraitai.length)])}>Try Random</span>
                        <textarea style={{ border: (isNsfw.sidePanelAIPortrait) ? '2px solid red' : '' }} type="text" multiple rows={2} placeholder="example - woman in business suit, wearing earphones, looking into camera" value={demo?.prompt} onChange={(e) => updateDemoPrompt(e, "sidePanelAIPortrait")} />
                        {isNsfw.sidePanelAIPortrait && <span style={{ fontSize: '12px', color: 'red' }}><ErrorIcon size='small' /> Unethical Or NSFW detected.</span>}
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>2/5. Face Photo (Optional)</b>{(!demo.facePhotoLink || demo.facePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                        {!demo.facePhotoLink ?
                            <>
                                <div style={{ maxWidth: '100%' }} className="content">
                                    {uploads
                                        && uploads.length > 0
                                        && <>
                                            <Checkbox
                                                id='recentUploadsDisplay'
                                                size='large'
                                                onClick={(e) => setRecentUploadsDisplay(e, "FACE")} // relevant method to handle your change
                                                color="primary"
                                                defaultChecked={demo.recentUploadsDisplay}
                                            />
                                            <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                            <br />
                                            {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                return <>
                                                    <img
                                                        key={i}
                                                        onClick={() => selectUploadedImage({
                                                            index: i,
                                                            photoType: "FACE",
                                                            subMenu: "AI_FACE_PHOTO",
                                                            link: photo.link,
                                                            filename: photo.filename,
                                                            width: photo.width,
                                                            height: photo.height
                                                        })}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                            margin: '5px',
                                                            border: (selectedPhoto
                                                                && selectedPhoto.FACE
                                                                && selectedPhoto.FACE.index === i
                                                                && selectedPhoto.FACE.photoType === "FACE"
                                                                && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO")
                                                                ? '2px solid #8c52ff' : '1px solid #eee',
                                                            width: (selectedPhoto
                                                                && selectedPhoto.FACE
                                                                && selectedPhoto.FACE.index === i
                                                                && selectedPhoto.FACE.photoType === "FACE"
                                                                && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO") ? '50px' : '40px'
                                                        }}
                                                        src={photo.link} />&nbsp;&nbsp;</>
                                            })}
                                            <br />
                                            <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                </div>
                                <button onClick={() => document.getElementById('face-photo').click()} className='edu-btn btn-small'>Select/Click Face Image</button>
                                <input onChange={onFaceImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="face-photo" name="face-photo" />
                            </> : <>
                                <img style={{ padding: '10px' }} src={demo.facePhotoLink} width='60px'></img>
                                <u style={{ fontSize: '12px', color: '#525FE1' }} onClick={removeFacePhoto}>Remove Selected Photo</u>
                            </>}

                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>3/5. Advance Configs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                        <Checkbox
                            id='face-create-advance-options'
                            size='large'
                            onClick={() => setFaceCreateAdvanceOptions(!faceCreateAdvanceOptions)}
                            color="primary"
                            defaultChecked={faceCreateAdvanceOptions}
                        />
                        <span style={{ fontSize: '12px' }}>Show</span>
                        {faceCreateAdvanceOptions && <>
                            <br />
                            {/* <label for='count'>Output Images Count (max 2): </label>
                            <input style={{ maxWidth: '23%', margin: '5px' }} onChange={updateDemoCount} type="number" min={1} max={2} id='count' placeholder='1'/ >
                            <br/> */}
                            <label for='width'>Aspect Ratio:&nbsp;</label>
                            <br />
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'SQUARE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('SQUARE')}><CropSquareIcon />Square 1:1</button>&nbsp;
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'PORTRAIT') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('PORTRAIT')}><CropPortraitIcon />Portrait 3:4</button>&nbsp;
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'LANDSCAPE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('LANDSCAPE')}><CropLandscapeIcon />Landscape 4:3</button>&nbsp;
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'CUSTOM') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('CUSTOM')}>Custom</button>
                            <br />
                            <label>Dimension(px): </label><br />
                            <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoWidth} type="text" id='width' placeholder='width(px)' value={demo.width} />
                            x
                            <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoHeight} type="text" id='height' placeholder='height(px)' value={demo.height} />
                        </>}
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>4/5. Existing Themes</b>{(selectedTheme === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                        <br />
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={4} key='theme_0'>
                                <div style={{ border: (selectedTheme === undefined) ? '2px solid #8c52ff' : '1px solid #eee', padding: '4px', cursor: 'pointer' }} onClick={() => { handleThemeClick(undefined) }}>
                                    <span style={{ fontSize: '10px' }}>None</span>
                                    <br />
                                    <br /><img src="https://instalooksai-hair-catalogue.s3.ap-south-1.amazonaws.com/no_theme.png" width='100%'></img>
                                    <br />
                                    <br />
                                </div>
                            </Grid>
                            {themes.instaportraitai && themes.instaportraitai.length > 0 &&
                                themes.instaportraitai.map((theme, index) => {
                                    return <Grid item xs={6} key={theme._id}>
                                        <div style={{ border: (selectedTheme && selectedTheme._id === theme._id) ? '2px solid #8c52ff' : '1px solid #eee', padding: '4px', cursor: 'pointer' }} onClick={() => { handleThemeClick(theme) }}>
                                            <span style={{ fontSize: '10px' }}>{(index + 1)}/{themes.anyAIPhoto?.length}. {theme.caption}</span>
                                            <img src={theme.imageAWSLink[0]} width={(theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0) ? '83%' : '100%'}></img>
                                            {theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0 && <span style={{ fontSize: '8px' }}><br /><StarIcon color='secondary' />For Paid Users</span>}
                                        </div>
                                    </Grid>
                                })
                            }
                        </Grid>
                        <br />
                        <br />
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>5/5. Inspirational Prompts</b>
                        <br />
                        <Grid container direction='row' spacing={1}>
                            {examples.map((ex, i) => {
                                return <Grid key={ex.id} item xs={12} style={{ border: '1px solid #6F6B80', padding: '8px', margin: '4px' }}>
                                    <Grid container direction='column'>
                                        <b style={{ fontSize: '10px' }}>{(i + 1)}/{examples.length}: {ex.caption}</b>
                                        <img src={ex.imageAWSLink} />
                                        <span style={{ fontSize: '12px' }}><b>Prompt:</b> {ex.prompt}</span>
                                        {selectedExample === ex.id ?
                                            <b style={{ color: '#7152E9' }}><CheckIcon />Prompt Copied</b>
                                            : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(ex.id, ex.prompt)}><ContentCopyIcon />Copy Prompt</button>}

                                    </Grid>
                                    <br />
                                </Grid>
                            })}
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ background: '#f1f1f1' }}>
                <Grid container direction='column'>
                    <Grid item>
                        <Grid container direction='row'>
                            <Grid item xs={2}>
                                <Checkbox
                                    id='ok_new_photo'
                                    size='large'
                                    onClick={() => setOk(!ok)}
                                    color="primary"
                                    defaultChecked={ok}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <span style={{ fontSize: '12px', lineHeight: '200%' }}>I take the complete legal responsibility & authority of the image.</span>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item>
                        <Grid container direction='row'>
                            <Grid item xs={7}>
                                {!demo.facePhotoLink && <span style={{ color: 'red', fontSize: '8px' }}>Select face-photo to get portrait with a particular face.</span>}
                            </Grid>
                            <Grid item xs={2}>
                                <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { updatedActiveMenu('none'); }}>Close</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    className='edu-btn btn-small btn-secondary'
                                    disabled={!ok}
                                    style={{ opacity: ok ? '100%' : '30%' }}
                                    onClick={() => { generateNewPhotos(); updatedActiveMenu('none'); }}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>}

        {isMobile && <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={activeMenu === 'face-replacement'} onClose={() => updatedActiveMenu('none')}>
            <DialogTitle><b className='title' style={{ color: 'blue' }}>Face Replacement</b></DialogTitle>
            <DialogContent>
                <div className={loading ? 'parentDisable' : ''} width="100%">
                    <div className='overlay-box'>
                        <GridLoader
                            color='#cc01ff'
                            loading={loading}
                            size={20} />
                    </div>
                </div>
                <div>
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>1/2. Base Photo</b>{(!demo.basePhotoLink || demo.basePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                        {!demo.basePhotoLink ?
                            <>
                                <div style={{ maxWidth: '100%' }} className="content">
                                    {uploads
                                        && uploads.length > 0
                                        && <>
                                            <Checkbox
                                                id='recentUploadsDisplay'
                                                size='large'
                                                onClick={(e) => setRecentUploadsDisplay(e, "BASE")} // relevant method to handle your change
                                                color="primary"
                                                defaultChecked={demo.recentUploadsDisplay}
                                            />
                                            <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                            <br />
                                            {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                return <>
                                                    <img
                                                        key={i}
                                                        onClick={() => selectUploadedImage({
                                                            index: i,
                                                            photoType: "BASE",
                                                            subMenu: "FACE_REPLACEMENT",
                                                            link: photo.link,
                                                            filename: photo.filename,
                                                            width: photo.width,
                                                            height: photo.height
                                                        })}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                            margin: '5px',
                                                            border: (selectedPhoto
                                                                && selectedPhoto.BASE
                                                                && selectedPhoto.BASE.index === i
                                                                && selectedPhoto.BASE.photoType === "BASE"
                                                                && selectedPhoto.BASE.subMenu === "FACE_REPLACEMENT")
                                                                ? '2px solid #8c52ff' : '1px solid #eee',
                                                            width: (selectedPhoto
                                                                && selectedPhoto.BASE
                                                                && selectedPhoto.BASE.index === i
                                                                && selectedPhoto.BASE.photoType === "BASE"
                                                                && selectedPhoto.BASE.subMenu === "FACE_REPLACEMENT") ? '60px' : '50px'
                                                        }}
                                                        src={photo.link} />&nbsp;&nbsp;</>
                                            })}
                                            <br />
                                            <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                </div>
                                <button onClick={() => document.getElementById('base-photo').click()} className='edu-btn btn-small'>Select/Click Base Image</button>
                                <input onChange={onBaseImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="base-photo" name="base-photo" />
                            </> : <>
                                <br />
                                <img style={{ padding: '10px' }} src={demo.basePhotoLink} width='60px'></img>
                                <u style={{ fontSize: '12px', color: '#525FE1' }} onClick={removeBasePhoto}>Remove Selected Photo</u>
                            </>}
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>2/2. Face Photo</b>{(!demo.facePhotoLink || demo.facePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                        {!demo.facePhotoLink ?
                            <>
                                <div style={{ maxWidth: '100%' }} className="content">
                                    {uploads
                                        && uploads.length > 0
                                        && <>
                                            <Checkbox
                                                id='recentUploadsDisplay'
                                                size='large'
                                                onClick={(e) => setRecentUploadsDisplay(e, "FACE")} // relevant method to handle your change
                                                color="primary"
                                                defaultChecked={demo.recentUploadsDisplay}
                                            />
                                            <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                            <br />
                                            {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                return <>
                                                    <img
                                                        key={i}
                                                        onClick={() => selectUploadedImage({
                                                            index: i,
                                                            photoType: "FACE",
                                                            subMenu: "FACE_REPLACEMENT",
                                                            link: photo.link,
                                                            filename: photo.filename,
                                                            width: photo.width,
                                                            height: photo.height
                                                        })}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                            margin: '5px',
                                                            border: (selectedPhoto
                                                                && selectedPhoto.FACE
                                                                && selectedPhoto.FACE.index === i
                                                                && selectedPhoto.FACE.photoType === "FACE"
                                                                && selectedPhoto.FACE.subMenu === "FACE_REPLACEMENT")
                                                                ? '2px solid #8c52ff' : '1px solid #eee',
                                                            width: (selectedPhoto
                                                                && selectedPhoto.FACE
                                                                && selectedPhoto.FACE.index === i
                                                                && selectedPhoto.FACE.photoType === "FACE"
                                                                && selectedPhoto.FACE.subMenu === "FACE_REPLACEMENT") ? '50px' : '40px'
                                                        }}
                                                        src={photo.link} />&nbsp;&nbsp;</>
                                            })}
                                            <br />
                                            <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                </div>
                                <button onClick={() => document.getElementById('face-photo').click()} className='edu-btn btn-small'>Select/Click Face Image</button>
                                <input onChange={onFaceImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="face-photo" name="face-photo" />
                            </> : <>
                                <br />
                                <img style={{ padding: '10px' }} src={demo.facePhotoLink} width='60px'></img>
                                <u style={{ fontSize: '12px', color: '#525FE1' }} onClick={removeFacePhoto}>Remove Selected Photo</u>
                            </>}
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ background: '#f1f1f1' }}>
                <Grid container direction='column'>
                    <Grid item>
                        <Grid container direction='row'>
                            <Grid item xs={2}>
                                <Checkbox
                                    id='ok_new_photo'
                                    size='large'
                                    onClick={() => setOk(!ok)}
                                    color="primary"
                                    defaultChecked={ok}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <span style={{ fontSize: '12px', lineHeight: '200%' }}>I take the complete legal responsibility & authority of the image.</span>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item>
                        <Grid container direction='row'>
                            <Grid item xs={7}>
                                {(!demo.basePhotoLink || !demo.facePhotoLink) && <span style={{ color: 'red', fontSize: '8px' }}><ErrorOutlineIcon />Select all required photos.</span>}
                            </Grid>
                            <Grid item xs={2}>
                                <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { updatedActiveMenu('none'); }}>Close</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    className='edu-btn btn-small btn-secondary'
                                    disabled={!demo.basePhotoLink || !demo.facePhotoLink || !ok}
                                    style={{
                                        opacity: (!demo.basePhotoLink || !demo.facePhotoLink || !ok) ? '30%' : "100%",
                                        cursor: (!demo.basePhotoLink || !demo.facePhotoLink || !ok) ? '' : "pointer"
                                    }}
                                    onClick={() => { generateReplacedPhoto(); updatedActiveMenu('none'); }}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>}

        {isMobile && <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={activeMenu === 'ai-photos'} onClose={() => updatedActiveMenu('none')}>
            <DialogTitle><b className='title' style={{ color: 'blue' }}>AI Any Photo</b></DialogTitle>
            <DialogContent>
                <div className={loading ? 'parentDisable' : ''} width="100%">
                    <div className='overlay-box'>
                        <GridLoader
                            color='#cc01ff'
                            loading={loading}
                            size={20} />
                    </div>
                </div>
                <div>
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>1/6. Explain Expected Photo</b>
                        <br />
                        <span style={{ fontSize: '12px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => updateDemoPrompt(undefined, "sidePanelAnyAIPhoto", randomPrompts.anyAIPhoto[Math.floor(Math.random() * randomPrompts.anyAIPhoto.length)])}>Try Random</span>
                        <textarea style={{ border: (isNsfw.sidePanelAnyAIPhoto) ? '2px solid red' : '' }} type="text" multiple rows={2} placeholder="example - woman in business suit, wearing earphones, looking into camera" value={demo?.prompt} onChange={(e) => updateDemoPrompt(e, "sidePanelAnyAIPhoto")} />
                        {isNsfw.sidePanelAnyAIPhoto && <span style={{ fontSize: '12px', color: 'red' }}><ErrorIcon size='small' /> Unethical Or NSFW detected.</span>}
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>2a/6. Reference Photo (Optional)</b>{(!demo.referencePhotoLink || demo.referencePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                        {!demo.referencePhotoLink ? <><div style={{ maxWidth: '100%' }} className="content">
                            {uploads
                                && uploads.length > 0
                                && <>
                                    <Checkbox
                                        id='recentUploadsDisplay'
                                        size='large'
                                        onClick={(e) => setRecentUploadsDisplay(e, "REFERENCE")} // relevant method to handle your change
                                        color="primary"
                                        defaultChecked={demo.recentUploadsDisplay}
                                    />
                                    <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                    <br />
                                    {demo.recentUploadsDisplay && demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                        return <>
                                            <img
                                                key={i}
                                                onClick={() => selectUploadedImage({
                                                    index: i,
                                                    photoType: "REFERENCE",
                                                    subMenu: "ANY_AI_PHOTO",
                                                    link: photo.link,
                                                    filename: photo.filename,
                                                    width: photo.width,
                                                    height: photo.height
                                                })}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '2px',
                                                    margin: '5px',
                                                    border: (selectedPhoto
                                                        && selectedPhoto.REFERENCE
                                                        && selectedPhoto.REFERENCE.index === i
                                                        && selectedPhoto.REFERENCE.photoType === "REFERENCE"
                                                        && selectedPhoto.REFERENCE.subMenu === "ANY_AI_PHOTO")
                                                        ? '2px solid #8c52ff' : '1px solid #eee',
                                                    width: (selectedPhoto
                                                        && selectedPhoto.REFERENCE
                                                        && selectedPhoto.REFERENCE.index === i
                                                        && selectedPhoto.REFERENCE.photoType === "REFERENCE"
                                                        && selectedPhoto.REFERENCE.subMenu === "ANY_AI_PHOTO") ? '50px' : '40px'
                                                }}
                                                src={photo.link} />&nbsp;&nbsp;</>
                                    })}
                                    <br />
                                    <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                        </div>
                            <button onClick={() => document.getElementById('reference-photo').click()} className='edu-btn btn-small'>Select/Click Reference Image</button>
                            <input onChange={onReferenceImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="reference-photo" name="reference-photo" />
                        </> : <>
                            <img style={{ padding: '10px' }} src={demo.referencePhotoLink} width='60px'></img>
                            <u style={{ fontSize: '12px', color: '#525FE1' }} className='edu-btn btn-small' onClick={removeReferencePhoto}>Remove Selected Photo</u>
                        </>}
                        {demo.referencePhotoLink &&
                            <div style={{ minWidth: '100%' }} className="content">
                                <br /><b>2b/6. Reference Photo Change Meter</b>
                                <div style={{ margin: '0 15% 0 15%', maxWidth: '70%' }} className="content">
                                    <Slider
                                        sx={CustomSliderStyles}
                                        size="large"
                                        aria-label="denoising_strength"
                                        defaultValue={demo.denoising_strength}
                                        min={0}
                                        max={100}
                                        valueLabelDisplay="auto"
                                        color="secondary"
                                        marks={denoisingStrengthLabel}
                                        onChange={onDenoisingStrengthChange}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>3/6. Face Photo (Optional)</b>{(!demo.facePhotoLink || demo.facePhotoLink === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                        {!demo.facePhotoLink ?
                            <>
                                <div style={{ maxWidth: '100%' }} className="content">
                                    {uploads
                                        && uploads.length > 0
                                        && <>
                                            <Checkbox
                                                id='recentUploadsDisplay'
                                                size='large'
                                                onClick={(e) => setRecentUploadsDisplay(e, "FACE")} // relevant method to handle your change
                                                color="primary"
                                                defaultChecked={demo.recentUploadsDisplay}
                                            />
                                            <span style={{ fontSize: '14px' }}>Select from recently uploaded</span>
                                            <br />
                                            {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                                return <>
                                                    <img
                                                        key={i}
                                                        onClick={() => selectUploadedImage({
                                                            index: i,
                                                            photoType: "FACE",
                                                            subMenu: "AI_FACE_PHOTO",
                                                            link: photo.link,
                                                            filename: photo.filename,
                                                            width: photo.width,
                                                            height: photo.height
                                                        })}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                            margin: '5px',
                                                            border: (selectedPhoto
                                                                && selectedPhoto.FACE
                                                                && selectedPhoto.FACE.index === i
                                                                && selectedPhoto.FACE.photoType === "FACE"
                                                                && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO")
                                                                ? '2px solid #8c52ff' : '1px solid #eee',
                                                            width: (selectedPhoto
                                                                && selectedPhoto.FACE
                                                                && selectedPhoto.FACE.index === i
                                                                && selectedPhoto.FACE.photoType === "FACE"
                                                                && selectedPhoto.FACE.subMenu === "AI_FACE_PHOTO") ? '50px' : '40px'
                                                        }}
                                                        src={photo.link} />&nbsp;&nbsp;</>
                                            })}
                                            <br />
                                            <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}
                                </div>
                                <button onClick={() => document.getElementById('face-photo').click()} className='edu-btn btn-small'>Select/Click Face Image</button>
                                <input onChange={onFaceImageUploaded} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" id="face-photo" name="face-photo" />
                            </> : <>
                                <img style={{ padding: '10px' }} src={demo.facePhotoLink} width='60px'></img>
                                <u style={{ fontSize: '12px', color: '#525FE1' }} onClick={removeFacePhoto}>Remove Selected Photo</u>
                            </>}

                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>4/6. Advance Configs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                        <Checkbox
                            id='face-create-advance-options'
                            size='large'
                            onClick={() => setAnyAIPhotoAdvanceOptions(!anyAIPhotoAdvanceOptions)}
                            color="primary"
                            defaultChecked={anyAIPhotoAdvanceOptions}
                        />
                        <span style={{ fontSize: '12px' }}>Show</span>
                        {anyAIPhotoAdvanceOptions && <>
                            <br />
                            {/* <label for='count'>Output Images Count (max 2): </label>
                            <input style={{ maxWidth: '23%', margin: '5px' }} onChange={updateDemoCount} type="number" min={1} max={2} id='count' placeholder='1'/ >
                            <br /> */}
                            <span>Tiling</span>
                            <Checkbox
                                id='tiling'
                                size='large'
                                onClick={onTilingSelection} // relevant method to handle your change
                                color="secondary"
                                defaultChecked={demo.tiling}
                            />
                            <br />
                            <label for='width'>Aspect Ratio:&nbsp;</label>
                            <br />
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'SQUARE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('SQUARE')}><CropSquareIcon />Square 1:1</button>&nbsp;
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'PORTRAIT') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('PORTRAIT')}><CropPortraitIcon />Portrait 3:4</button>&nbsp;
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'LANDSCAPE') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('LANDSCAPE')}><CropLandscapeIcon />Landscape 4:3</button>&nbsp;
                            <button className={`edu-btn btn-small ${(demo.aspectRatio !== 'CUSTOM') ? 'btn-bg-alt' : ""}`} onClick={() => updateAspectRatio('CUSTOM')}>Custom</button>
                            <br />
                            <label for='width'>Dimension (px)</label><br />
                            <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoWidth} type="text" id='width' placeholder={demo.width} />
                            x
                            <input style={{ maxWidth: '26%', margin: '5px' }} onChange={updateDemoHeight} type="text" id='height' placeholder={demo.height} />
                        </>}
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>5/6. Existing Themes</b>{(selectedTheme === undefined) && <b style={{ color: "red", fontSize: "12px" }}> (Not selected)</b>}
                        <br />
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={4} key='theme_0'>
                                <div style={{ border: (selectedTheme === undefined) ? '2px solid #8c52ff' : '1px solid #eee', padding: '4px', cursor: 'pointer' }} onClick={() => { handleThemeClick(undefined) }}>
                                    <span style={{ fontSize: '10px' }}>None</span>
                                    <img src="/images/others/no_style.png" width='100%'></img>
                                </div>
                            </Grid>
                            {themes.anyAIPhoto && themes.anyAIPhoto.length > 0 &&
                                themes.anyAIPhoto.map((theme, index) => {
                                    return <Grid item xs={6} key={theme._id}>
                                        <div style={{ border: (selectedTheme && selectedTheme._id === theme._id) ? '2px solid #8c52ff' : '1px solid #eee', padding: '4px', cursor: 'pointer' }} onClick={() => { handleThemeClick(theme) }}>
                                            <span style={{ fontSize: '10px' }}>{(index + 1)}/{themes.anyAIPhoto?.length}. {theme.caption}</span>
                                            <img src={theme.imageAWSLink[0]} width={(theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0) ? '83%' : '100%'}></img>
                                            {theme.onlyPaid && user.membership.type === 'FREE' && user.membership.photosCredit <= 0 && <span style={{ fontSize: '8px' }}><br /><StarIcon color='secondary' />For Paid Users</span>}
                                        </div>
                                    </Grid>
                                })
                            }
                        </Grid>

                        <br />
                        <br />
                    </div>
                    <br />
                    <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                        <b>6/6. Inspirational Prompts</b>
                        <br />
                        <Grid container direction='row' spacing={1}>
                            {examples.map((ex, i) => {
                                return <Grid key={ex.id} item xs={12} style={{ border: '1px solid #6F6B80', padding: '8px', margin: '4px' }}>
                                    <Grid container direction='column'>
                                        <b style={{ fontSize: '10px' }}>{(i + 1)}/{examples.length}: {ex.caption}</b>
                                        <img src={ex.imageAWSLink} />
                                        <span style={{ fontSize: '12px' }}><b>Prompt:</b> {ex.prompt}</span>
                                        {selectedExample === ex.id ?
                                            <b style={{ color: '#7152E9' }}><CheckIcon />Prompt Copied</b>
                                            : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(ex.id, ex.prompt)}><ContentCopyIcon />Copy Prompt</button>}

                                    </Grid>
                                    <br />
                                </Grid>
                            })}
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ background: '#f1f1f1' }}>
                <Grid container direction='column'>
                    <Grid item>
                        <Grid container direction='row'>
                            <Grid item xs={2}>
                                <Checkbox
                                    id='ok_new_photo'
                                    size='large'
                                    onClick={() => setOk(!ok)}
                                    color="primary"
                                    defaultChecked={ok}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <span style={{ fontSize: '12px', lineHeight: '200%' }}>I take the complete legal responsibility & authority of the image.</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row'>
                            <Grid item xs={7}>
                            </Grid>
                            <Grid item xs={2}>
                                <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { updatedActiveMenu('none'); }}>Close</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    className='edu-btn btn-small btn-secondary'
                                    onClick={() => { generateAnyAIPhotos(); updatedActiveMenu('none'); }}
                                    disabled={!ok}
                                    style={{
                                        opacity: (!ok) ? '30%' : "100%",
                                        cursor: (!ok) ? '' : "pointer"
                                    }}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </DialogActions>
        </Dialog>}

        <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={activeMenu === 'more'} onClose={() => updatedActiveMenu('none')}>
            <DialogTitle><b className='title' style={{ color: 'blue' }}>More Options</b></DialogTitle>
            <DialogContent>
                <div>
                    <Link to="/my-profile" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); fetchUser(); }}>
                        <Button color="primary">
                            <AccountCircleIcon fontSize='large' /><b>User Profile</b>
                        </Button>
                    </Link>
                    <hr />
                    <Link to="/history" style={{ textDecoration: activeMenu === 'history' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none');}}>
                        <Button color="primary">
                            <WorkHistoryIcon fontSize='large' /><b>My History</b>
                        </Button>
                    </Link>
                    <hr />
                    <Link to="/instaphotoai-teams" style={{ textDecoration: activeMenu === 'team' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none');}}>
                        <Button color="primary">
                            <Groups2Icon fontSize='large' /><b>Team Gallery</b>
                        </Button>
                    </Link>
                    <hr />
                    <Link to="/blogs" style={{ textDecoration: activeMenu === 'blogs' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); }}>
                        <Button color="primary">
                            <DescriptionIcon fontSize='large' /><b>Blogs</b>
                        </Button>
                    </Link>
                    {/* <hr />
                    <Link to="/pricing" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); }}>
                        <Button color="primary">
                            <LocalOfferIcon fontSize='large' /> <b>Pricing</b>
                        </Button>
                    </Link> */}
                    <hr />
                    <Link to="/faq" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); }}>
                        <Button color="primary">
                            <HelpIcon fontSize='large' /><b>FAQs</b>
                        </Button>
                    </Link>
                    <hr />
                    <Link to="/contact-us" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); }}>
                        <Button color="primary">
                            <SupportAgentIcon fontSize='large' /><b>Contact Us</b>
                        </Button>
                    </Link>
                    <hr />
                    <Link to="/" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={logout}>
                        <Button color="primary">
                            <LogoutIcon fontSize='large' /><b>Logout</b>
                        </Button>
                    </Link>
                </div>
            </DialogContent>
            <DialogActions>
                <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { updatedActiveMenu('none'); }}>Close</span>
            </DialogActions>
        </Dialog>

        <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={paidUserDialog} onClose={() => setPaidUserDialog(false)}>
            <DialogTitle><b className='title'>Premium Feature</b></DialogTitle>
            <DialogContent>
                <div>
                    <span>Upgrade to a paid user and use all the premium features.</span>
                </div>
            </DialogContent>
            <DialogActions>
                <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { setPaidUserDialog(false); }}>Close</span>
                <Link to="/pricing" onClick={() => { setPaidUserDialog(true); }}>
                    <button className="edu-btn btn-primary btn-small">
                        Explore Pricing
                    </button>
                </Link>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default SidePanel;
