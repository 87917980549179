import React, { useState, useEffect } from 'react';
import HeaderOne from './header/HeaderOne';
import GridLoader from "react-spinners/GridLoader";
import { useGlobalContext } from '../context/global_context';
import SidePanel from '../pages/homepages/sidePanel';
import ActionPanel from '../pages/homepages/actionPanel';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FooterOne from './footer/FooterOne';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';
import Photo from '../components/photos/Photo';

const LayoutAfterLogin = ({ children }) => {
    const [showDialog, setShowDialog] = useState(false);

    const {
        user,
        path,
        loading,
        isMobile,
        generatedPhotoOpen,
        updateGeneratedPhotoOpen,
        needLogin,
        setNeedLogin,
        updateRegisterEmail,
        updatePassword,
        submit,
        googleSignIn
    } = useGlobalContext();

    useEffect(() => {
        const lastDisplay = localStorage.getItem('lastAnnouncementDisplay');
        const currentTime = new Date().getTime();
        const fiveHours = 2 * 60 * 60 * 1000;
    
        // Check if 5 hours have passed since the last display
        if (!lastDisplay || currentTime - lastDisplay >= fiveHours) {
          setShowDialog(true);
          localStorage.setItem('lastAnnouncementDisplay', currentTime);
        }
    
        // Set a timeout to show the dialog every 5 hours
        const interval = setInterval(() => {
          setShowDialog(true);
          localStorage.setItem('lastAnnouncementDisplay', new Date().getTime());
        }, fiveHours);
    
        return () => clearInterval(interval);
    }, []);

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const useStyles = makeStyles({
        fullScreenContentAreaWrapper: {
            position: 'absolute',
            top: 70,
            left: '11.9%',
            background: '#fff',
            width: '87%',
            height: 'auto',
            overflow: 'scroll',
            direction: 'column',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center'
        },
        halfScreenContentAreaWrapper: {
            position: 'absolute',
            top: 70,
            left: (isMobile) ? '0%' : '42%',
            background: '#fff',
            height: 'auto',
            overflow: 'scroll',
            direction: 'column',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            zIndex: 9
        }
    });

    const classes = useStyles();

    const handleGeneratedPhotoClose = () => {
        updateGeneratedPhotoOpen(false);
    }

    let outputPanelClass = classes.halfScreenContentAreaWrapper;
    if(path === '/home' 
    || path === '/my-profile' 
    || path === '/ai-photo-examples'
    || path === '/history'
    || path === '/instaphotoai-teams') {
        outputPanelClass = classes.fullScreenContentAreaWrapper
    }

    const handleVisitSite = () => {
        window.location.href = 'https://rainforest.zone';
      };

    return (
        <main>
            {showDialog && (
                <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={true} onClose={handleCloseDialog}>
                <DialogTitle><b className='title' style={{color:'blue'}}>InstaPhotoAI will shut on 24th November, 2024. It is changed to "Rainforest.Zone"</b></DialogTitle>
                <DialogContent>
                    <div className="edu-feature-list color-variation-1">
                        <div className="mb--10 row" style={{padding:'4%'}}>
                            Rainforest.zone has following awesome features; 
                            <ul>
                                <li>Parallel Multiple Image Generations</li>
                                <li>Pixel-perfect AI Images</li>
                                <li>Spectacular AI Editing</li>
                                <li>Person with specific outfit </li>
                                <li>Image Animations</li>
                                <li>AI Videos</li>
                            </ul>
                        </div>
                        <button style={{fontSize:'14px', width:'100%'}} className="edu-btn btn-white mb--10" onClick={handleVisitSite}><img src="/images/logo/rainforest-logo.png" />Explore Rainforest.zone </button>

                    </div>
                </DialogContent>
                <DialogActions>
                    <b className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={handleCloseDialog}>Close</b>
                </DialogActions>
            </Dialog>
            )}
            <HeaderOne />
            
            {!showDialog && <SidePanel /> }
            { (path === '/ai-portrait'
                || path === '/face-replacement'
                || path === '/ai-photos') && <ActionPanel /> }
            
            <Box className={outputPanelClass}>
                {children}
                <FooterOne />
            </Box>

            {loading == true && <div className={loading ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <GridLoader
                        color='#cc01ff'
                        loading={loading}
                        size={20} />
                </div>
            </div>}
            <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={generatedPhotoOpen} onClose={handleGeneratedPhotoClose}>
                <DialogTitle><b className='title'>Created Photo</b></DialogTitle>
                <DialogContent>
                    <div className="edu-feature-list color-variation-1">
                        <div style={{ minWidth: '100%' }} className="content">
                            <div >
                                <div className="row" style={{
                                    display: 'block',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {(user.customPhoto && Object.keys(user.customPhoto).length > 0) ? <Photo sharePresent={user.customPhoto.sharePresent} modelPrompt={user.customPhoto.modelPrompt} createdBy={user.customPhoto.createdBy} photoId={user.customPhoto._id} imageSDLink={user.customPhoto.imageSDLink} visibleForAll={user.customPhoto.visibleForAll} reusePresent="false" likePresent="false" themeKey={user.customPhoto?.theme} />
                                    : user.themePhoto && Object.keys(user.themePhoto).length > 0 && <Photo sharePresent={user.themePhoto.sharePresent} modelPrompt={user.themePhoto.modelPrompt} createdBy={user.themePhoto.createdBy} photoId={user.themePhoto._id} imageSDLink={user.themePhoto.imageSDLink} visibleForAll={user.themePhoto.visibleForAll} reusePresent="false" likePresent="false" themeKey={user.themePhoto?.theme}/>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className='edu-btn btn-small' onClick={handleGeneratedPhotoClose}
                        color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={needLogin} onClose={() => setNeedLogin(false)}>
                <DialogTitle><b className='title'>Need To Login/Register</b></DialogTitle>
                <DialogContent>
                    <div className={loading ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                            <GridLoader
                                color='#cc01ff'
                                loading={loading}
                                size={20} />
                        </div>
                    </div>
                    <div className="edu-feature-list color-variation-1">
                        <div className="mb--10 row">
                            <button className="edu-btn btn-white mb--10" onClick={googleSignIn}><img src="/images/logo/g-logo.jpeg" /> Sign in with Google </button>
                        </div>
                        {/* <div className="login-form">
                                    <div className="mb--10 row align-text-center">
                                        <br /><Divider />
                                    </div>
                                    <div className="mb--10 row">
                                        <input type="email" placeholder="Email" onChange={updateRegisterEmail} />
                                    </div>
                                    <div className="row mb--10">
                                        <input type="password" placeholder="Password" onChange={updatePassword} />
                                    </div>
                                </div> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setNeedLogin(false)}>Close</span>
                    {/* <Button className='edu-btn btn-small' onClick={submit}
                                color="primary">
                                Submit
                            </Button> */}
                </DialogActions>
            </Dialog>
        </main>
    )
}
export default LayoutAfterLogin;