import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const inIndia = timeZone.indexOf('Calcutta') >= 0;

const items = [
    // {
    //     title: 'Cost Effective Credits',
    //     info: 'Photo Credits are without time constraint. Can be used anytime.',
    //     icon: 'icon-price-tag-3-line',
    //     shape: 'shape-bg-1',
    //     link: '/pricing'
    // },
    {
        title: 'Reliable APIs',
        info: 'Use our efficient API service, hosted on best servers of AWS.',
        icon: 'icon-science',
        shape: 'shape-bg-2',
        link: '/'
    },
    {
        title: 'Quick photo generation',
        info: 'Taking less than 20 seconds to generate a photo.',
        icon: 'icon-time-line',
        shape: 'shape-bg-3',
        link: '/'
    },
    {
        title: 'Great face accuracy',
        info: 'Generating face 95% accurate with just a single photo of the person.',
        icon: 'icon-trophy-line',
        shape: 'shape-bg-1',
        link: '/'
    }
];

const OurUSPs = ( { wrapperClass, itemClass } ) => {
    return (
        <div className={ wrapperClass || 'row mt--10' }>
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeIn"
                    animateOut="fadeInOut"
                    className={ itemClass || 'col-lg-3 col-md-6 col-12' }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={`service-card service-card-7 ${data.shape}`}>
                        <div className="inner">
                            <a href={ data.link }>
                            <div className="icon">
                                <i className={ data.icon }></i>
                            </div>
                            </a>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default OurUSPs;