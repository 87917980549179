import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import Photo from '../../components/photos/Photo';
import { useGlobalContext } from '../../context/global_context';
import { useParams } from "react-router-dom";
import BannerFour from '../../components/banner/BannerFour';

const PhotoPage = () => {
    const { id } = useParams()
    console.log("id: ", id)

    const {
        user,
        photopage,
        getPhotoDetails
    } = useGlobalContext();


    if (photopage && (!photopage._id || photopage._id !== id)) {
        getPhotoDetails(id);
    }

    return (
        <>
            <SEO title="InstaPhotoAI" />
            {photopage === undefined
                && <><Layout><br/><b style={{color:'red'}}>Invalid Photo Or Error while fetching photo.</b><br/></Layout></>}
            {photopage !== undefined
                && Object.keys(photopage).length === 0
                && <><Layout><br/><b style={{color:'blue'}}>Fetching Photo...</b><br/></Layout></>}
            {photopage !== undefined
                && Object.keys(photopage).length > 0
                && (!user || !user.token)
                && <Layout>
                    <div className="row">
                        <div className="col-0 col-lg-4">
                        </div>
                        <div className="col-12 col-lg-4" style={{ maxHeight: '1216px', maxWidth: '832px' }}>
                            <div className="login-register-page-wrapper bg-color-white">
                                <div className="col align-items-center">
                                    <Photo creator={photopage.creator} modelPrompt={photopage.modelPrompt} createdBy={photopage.createdBy} likePresent="false" reusePresent="false" downloadPresent="false" photoId={photopage._id} visibilityPresent="false" themeKey={photopage.themeKey} prompt={photopage.prompt} imageSDLink={photopage.imageSDLink} deletePresent="false" />
                                </div>
                            </div>
                        </div>
                        <div className="col-0 col-lg-4">
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <BannerFour />

                </Layout>
            }
            {photopage !== undefined
                && Object.keys(photopage).length > 0
                && user && user.token &&
                <LayoutAfterLogin>
                    <Photo creator={photopage.creator} modelPrompt={photopage.modelPrompt} createdBy={photopage.createdBy} liked={user._id && photopage.likedBy && photopage.likedBy[user._id] === true} likedCount={photopage.likedCount} reusedCount={photopage.reusedCount} photoId={photopage._id} downloadPresent="false" visibilityPresent="false" themeKey={photopage.themeKey} prompt={photopage.prompt} imageSDLink={photopage.imageSDLink} deletePresent="false" />
                </LayoutAfterLogin>
            }
        </>
    )
}

export default PhotoPage;