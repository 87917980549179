import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogCard from '../../components/blogs/BlogCard';
import SectionTitle from '../../components/sectionTitle/SectionTitle';

const Blogs = () => {
    return (
        <>
            <SEO title="InstaPhotoAI | Blogs" />
            <Layout>
                <BreadcrumbOne
                    title="Blogs"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blogs"
                />
                <div className="edu-section-gap container edu-pricing-area edu-section-gap bg-image">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan="Generate AI Images within seconds"
                                title="Using AI Image Generator"
                            />
                        </div>
                    </div>
                    <BlogCard />
                </div>
            </Layout>
        </>
    )
}

export default Blogs;