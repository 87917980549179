const global_reducer = (state, action) => {
    if (action.type === 'UPDATE_ISMOBILE') {
        return {
            ...state,
            isMobile: action.payload
        }
    }

    if (action.type === 'LOGIN_SUCCESS') {
        localStorage.setItem('tbcs_user', JSON.stringify(action.payload.user))
        return {
            ...state,
            user: action.payload.user,
            path: action.payload.path,
            activeMenu: action.payload.activeMenu
        }
    }

    if (action.type === 'UPDATE_USER') {
        let oldUser = JSON.parse(localStorage.getItem('tbcs_user')) || {};
        oldUser = {...oldUser, ...action.payload};

        localStorage.setItem('tbcs_user', JSON.stringify(oldUser))
        return {
            ...state,
            user: {
                ...state.user,
                ...action.payload
            }
        }
    }

    if (action.type === 'SET_LOADER') {
        return {
            ...state,
            loading: action.payload,
        }
    }

    if (action.type === 'UPDATE_REGISTER_EMAIL') {
        return {
            ...state,
            email: action.payload,
        }
    }

    if (action.type === 'UPDATE_PASSWORD') {
        return {
            ...state,
            password: action.payload
        }
    }

    if (action.type === 'PAYMENT_FAILED') {
        return {
            ...state,
            session: {
                ...state.session,
                payment: {
                    error: {
                        message: action.payload.message
                    }
                }
            }
        }
    }

    if (action.type === 'LOGOUT') {
        localStorage.removeItem("tbcs_user");
        localStorage.removeItem("tbcs_path");
        localStorage.removeItem("tbcs_themes");
        localStorage.removeItem("tbcs_uploads");
        
        return {
            ...state
        }
    }

    if (action.type === 'UPDATE_PATH') {
        localStorage.setItem('tbcs_path', JSON.stringify(action.payload))
        return {
            ...state,
            path: action.payload,
        }
    }

    if(action.type === 'SET_MEMBERSHIP_DETAILS') {
        const newUser = { ...state.user, membership: {
            ...state.user.membership,
            ...action.payload
        } };

        localStorage.setItem('tbcs_user', JSON.stringify(newUser));

        return {
            ...state,
            user: {
                ...state.user,
                membership: {
                    ...state.user.membership,
                    ...action.payload
                }
            }
        }
    }

    if(action.type === 'UPDATE_ACTIVE_MENU') {
        return {
            ...state,
            activeMenu: action.payload,
            selectedTheme: undefined
        }
    }

    if(action.type === 'UPDATE_ACTIVE_SUB_MENU') {
        return {
            ...state,
            activeSubMenu: action.payload,
            selectedPhoto: undefined,
            demo: {
                ...state.demo,
                prompt: undefined,
                count: undefined,
                facePhotoLink: undefined,
                facePhotoLinkFilename: undefined,
                basePhotoLink: undefined,
                basePhotoLinkFilename: undefined,
                referencePhotoLink: undefined,
                referencePhotoLinkFilename: undefined,
                aspectRatio: "SQUARE",
                width: 1024,
                height: 1024
            }
        }
    }

    if(action.type === 'DELETE_PHOTO') {
        const oldUser = JSON.parse(localStorage.getItem('tbcs_user')) || {};

        // Check theme photo
        if(oldUser.themePhoto && oldUser.themePhoto._id === action.payload) {
            oldUser.themePhoto.deleted = true;
        } 

        // Check custom photo
        if(oldUser.customPhoto && oldUser.customPhoto._id === action.payload) {
            oldUser.customPhoto.deleted = true;
        } 

        // Check theme phtos
        if(oldUser.themePhotos && oldUser.themePhotos.length>0) {
            for(let i = 0; i<oldUser.themePhotos.length; i++) {
               if(oldUser.themePhotos[i]._id === action.payload) {
                    oldUser.themePhotos[i].deleted = true;
                    break;
               } 
            }
        }

        // Check custom photos
        if(oldUser.customPhotos && oldUser.customPhotos.length>0) {
            for(let i = 0; i<oldUser.customPhotos.length; i++) {
               if(oldUser.customPhotos[i]._id === action.payload) {
                    oldUser.customPhotos[i].deleted = true;
                    break;
               } 
            }
        }

        localStorage.setItem('tbcs_user', JSON.stringify(oldUser));

        return {
            ...state,
            user: {
                ...state.user,
                themePhoto: (state.user.themePhoto && state.user.themePhoto._id == action.payload) ?
                {
                    ...state.user.themePhoto,
                    deleted: true
                } : {
                    ...state.user.themePhoto
                },
                customPhoto: (state.user.customPhoto && state.user.customPhoto._id == action.payload) ?
                {
                    ...state.user.customPhoto,
                    deleted: true
                } : {
                    ...state.user.customPhoto
                },
                themePhotos: state.user.themePhotos.map(photo => {
                    if(photo._id === action.payload) {
                        return {
                            ...photo,
                            deleted: true
                        }
                    } else {
                        return photo;
                    }
                }),
                customPhotos: state.user.customPhotos.map(photo => {
                    if(photo._id === action.payload) {
                        return {
                            ...photo,
                            deleted: true
                        }
                    } else {
                        return photo;
                    }
                })
            }
        }
    }

    if(action.type === 'UPDATE_GENERATED_PHOTO_DIALOG') {
        return {
            ...state,
            generatedPhotoOpen: action.payload
        }
    }

    if(action.type === 'DECREMENT_PHOTO_CREDIT') {
        const oldUser = JSON.parse(localStorage.getItem('tbcs_user')) || {};
        let newPhotoCredit = 0;

        if(oldUser.membership && oldUser.membership.photosCredit > 0 && action.payload > 0) {
            oldUser.membership.photosCredit-= action.payload;
        } else if(oldUser.membership && oldUser.membership.photosCredit > 0) {
            oldUser.membership.photosCredit--;
        }

        newPhotoCredit = oldUser.membership.photosCredit;
        localStorage.setItem('tbcs_user', JSON.stringify(oldUser));
        return {
            ...state,
            user: {
                ...state.user,
                membership: {
                    ...state.user.membership,
                    photosCredit: newPhotoCredit
                }
            }
        }
    }

    if(action.type === 'UPDATE_ACCOUNT_TYPE') {
        const oldUser = JSON.parse(localStorage.getItem('tbcs_user')) || {};
        oldUser.accountType = action.payload;
        localStorage.setItem('tbcs_user', JSON.stringify(oldUser));
        return {
            ...state,
            user: {
                ...state.user,
                accountType: action.payload
            }
        }
    }

    if(action.type === 'CLEAR_PHOTO_PAGE') {
        return {
            ...state,
            photopage: undefined
        }
    }

    if(action.type === 'UPDATE_PHOTO_PAGE') {
        return {
            ...state,
            photopage: {
                ...action.payload
            }
        }
    }

    if(action.type === 'SET_DEMO_PROMPT') {
        return {
            ...state,
            demo: {
                ...state.demo,
                prompt: action.payload
            }
        }
    }

    if(action.type === 'UPDATE_FACE_PHOTO_LINK') {
        let newUploads = JSON.parse(localStorage.getItem('tbcs_uploads')) || [];
        
        if(action.payload.link) {
            newUploads.unshift(action.payload);
            if(newUploads.length > 5) {
                newUploads.pop();
            }
    
            localStorage.setItem('tbcs_uploads', JSON.stringify(newUploads))    
        }
        
        return {
            ...state,
            demo: {
                ...state.demo,
                facePhotoLink: action.payload.link,
                facePhotoLinkFilename: action.payload.filename
            },
            uploads: newUploads
        }
    }

    if(action.type === 'SET_INTERMEDIATE_PHOTO') {
        return {
            ...state,
            demo: {
                ...state.demo,
                intermediateImage: {
                    base64: action.payload.intermediateImage
                }
            }
        }
    }

    if(action.type === 'SET_DEMO_GENERATED_PHOTOS') {
        let oldPhotos = state.demo.photos || [];
        let newPhotos = [{imageAWSLink: action.payload.imageAWSLink, _id: action.payload.photoId}];

        if(action.payload == undefined) {
            newPhotos = undefined
        } else {
            newPhotos = [...newPhotos, ...oldPhotos];
        }

        return {
            ...state,
            demo: {
                ...state.demo,
                photos: newPhotos,
                intermediateImage: {}
            }
        }
    }

    if(action.type === 'SET_DEMO_COUNT') {
        return {
            ...state,
            demo: {
                ...state.demo,
                count: action.payload
            }
        }
    }

    if(action.type === 'SET_DEMO_WIDTH') {
        return {
            ...state,
            demo: {
                ...state.demo,
                width: action.payload
            }
        }
    }

    if(action.type === 'SET_DEMO_HEIGHT') {
        return {
            ...state,
            demo: {
                ...state.demo,
                height: action.payload
            }
        }
    }

    if(action.type === 'UPDATE_BASE_PHOTO_LINK') {
        let newUploads = JSON.parse(localStorage.getItem('tbcs_uploads')) || [];
        
        if(action.payload.link) {
            newUploads.unshift(action.payload);
            if(newUploads.length > 5) {
                newUploads.pop();
            }
    
            localStorage.setItem('tbcs_uploads', JSON.stringify(newUploads))    
        }
        
        return {
            ...state,
            demo: {
                ...state.demo,
                basePhotoLink: action.payload.link,
                basePhotoLinkFilename: action.payload.filename
            },
            uploads: newUploads
        }
    }

    if(action.type === 'SET_API_CALL_DURATION') {
        return {
            ...state,
            demo: {
                ...state.demo,
                apiCallDuration: action.payload
            }
        } 
    }

    if(action.type === 'SET_TILING') {
        return {
            ...state,
            demo: {
                ...state.demo,
                tiling: action.payload
            }
        }
    }

    if(action.type === 'SET_DENOISING_STRENGTH') {
        return {
            ...state,
            demo: {
                ...state.demo,
                denoising_strength: action.payload
            }
        } 
    }

    if(action.type === 'UPDATE_REFERENCE_PHOTO_LINK') {
        let newUploads = JSON.parse(localStorage.getItem('tbcs_uploads')) || [];
        
        if(action.payload.link) {
            newUploads.unshift(action.payload);
            if(newUploads.length > 5) {
                newUploads.pop();
            }
    
            localStorage.setItem('tbcs_uploads', JSON.stringify(newUploads))    
        }
        
        return {
            ...state,
            demo: {
                ...state.demo,
                referencePhotoLink: action.payload.link,
                referencePhotoLinkFilename: action.payload.filename
            },
            uploads: newUploads
        } 
    }

    if(action.type === 'SET_ACTIVE_SUB_MENU_GENDER') {
        return {
            ...state,
            activeSubMenuGender: action.payload
        }
    }

    if(action.type === 'SET_HAIR_CUT') {
        return {
            ...state,
            activeHairCut: action.payload
        }
    }

    if(action.type === 'SET_DEMO_GENERATED_PHOTOS_NAME') {
        return {
            ...state,
            demo: {
                ...state.demo,
                photosName: [action.payload]
            }
        }
    }
    
    if(action.type === 'SET_HAIR_CUSTOMISATION') {
        return {
            ...state,
            demo: {
                ...state.demo,
                hairCustomisation: action.payload
            }
        }
    }

    if(action.type === 'SET_SELECTED_PHOTO') {
        if(action.payload.photoType && !action.payload.subMenu) {
            if(action.payload.photoType === "FACE") {
                return {
                    ...state,
                    selectedPhoto: {
                        ...state.selectedPhoto,
                        [action.payload.photoType]: undefined
                    },
                    demo: {
                        ...state.demo,
                        facePhotoLink: undefined,
                        facePhotoLinkFilename: undefined
                    }
                }
            } else if(action.payload.photoType === "BASE") {
                return {
                    ...state,
                    selectedPhoto: {
                        ...state.selectedPhoto,
                        [action.payload.photoType]: undefined
                    },
                    demo: {
                        ...state.demo,
                        basePhotoLink: undefined,
                        basePhotoLinkFilename: undefined
                    }
                }
            } else if(action.payload.photoType === "REFERENCE") {
                return {
                    ...state,
                    selectedPhoto: {
                        ...state.selectedPhoto,
                        [action.payload.photoType]: undefined
                    },
                    demo: {
                        ...state.demo,
                        basePhotoLink: undefined,
                        basePhotoLinkFilename: undefined
                    }
                }
            }
        }

        if(action.payload && action.payload.subMenu === "AI_FACE_PHOTO") {
            return {
                ...state,
                selectedPhoto: {
                    [action.payload.photoType]: action.payload
                },
                demo: {
                    ...state.demo,
                    facePhotoLink: action.payload.link,
                    facePhotoLinkFilename: action.payload.filename
                }
            }
        } else if(action.payload && action.payload.subMenu === "FACE_REPLACEMENT") {
            if(action.payload.photoType === "FACE") {
                return {
                    ...state,
                    selectedPhoto: {
                        ...state.selectedPhoto,
                        [action.payload.photoType]: action.payload
                    },
                    demo: {
                        ...state.demo,
                        facePhotoLink: action.payload.link,
                        facePhotoLinkFilename: action.payload.filename
                    }
                }
            } else if(action.payload.photoType === "BASE") {
                return {
                    ...state,
                    selectedPhoto: {
                        ...state.selectedPhoto,
                        [action.payload.photoType]: action.payload
                    },
                    demo: {
                        ...state.demo,
                        basePhotoLink: action.payload.link,
                        basePhotoLinkFilename: action.payload.filename
                    }
                }
            }      
        } else if(action.payload && action.payload.subMenu === "ANY_AI_PHOTO") {
            return {
                ...state,
                selectedPhoto: {
                    [action.payload.photoType]: action.payload
                },
                demo: {
                    ...state.demo,
                    referencePhotoLink: action.payload.link,
                    referencePhotoLinkFilename: action.payload.filename,
                    width: action.payload.width,
                    height: action.payload.height
                }
            }
        }

        return {
            ...state,
            selectedPhoto: {
                [action.payload.photoType]: action.payload
            },
        }
    }

    if (action.type === 'UPDATE_DEMO_VIDEO'){
        return {
            ...state,
            demoVideo: {
                index: action.payload.index,
                title: action.payload.title,
                url: action.payload.url
            }
        }
    }

    if (action.type === 'UPDATE_ASPECT_RATIO') {
        let width = 1024;
        let height = 1024;
        if(state.demo.width) {
            width = state.demo.width;
        }

        if(action.payload === "SQUARE") {
            width = 1024;
            height = 1024;
        } else if(action.payload === "PORTRAIT") {
            width = 832;
            height = 1216;
        } else if(action.payload === "LANDSCAPE") {
            width = 1216;
            height = 832;
        }

        return {
            ...state,
            demo: {
                ...state.demo,
                aspectRatio: action.payload,
                width,
                height
            }
        }
    }

    if(action.type === 'SET_RECENT_UPLOADS_DISPLAY') {
        return {
            ...state,
            demo: {
                ...state.demo,
                recentUploadsDisplay: action.payload
            }
        }
    }

    if (action.type === 'SET_NEED_LOGIN') {
        return {
            ...state,
            needLogin: action.payload
        }
    }
    
    if (action.type === 'REFRESH_USER_DETAILS') {
        return {
            ...state,
            refreshUserDetails: action.payload
        }
    }

    if (action.type === 'UPDATE_EXAMPLES') {
        return {
            ...state,
            examples: action.payload
        }
    }
    
    if (action.type === 'UPDATE_SELECTED_THEME') {
        return {
            ...state,
            selectedTheme: action.payload
        }
    }

    if (action.type === 'UPDATE_THEMES') {
        let appName = action.payload.app;

        if(!action.payload.app || action.payload.app.length == 0) {
            appName = 'all'
        }

        return {
            ...state,
            themes: {
                ...state.themes,
                [appName]:action.payload.themes
            }
        }
    }

    if (action.type === 'UPDATE_RANDOM_PROMPT') {
        let appName = action.payload.app;

        if(!action.payload.app || action.payload.app.length == 0) {
            appName = 'all'
        }

        return {
            ...state,
            randomPrompts: {
                ...state.randomPrompts,
                [appName]:action.payload.randomPrompts
            }
        }
    }

    if(action.type === 'SET_SELECTED_BLOG') {
        return {
            ...state,
            selectedBlog: action.payload
        }
    }
    
    if(action.type === 'UPDATE_FACE_CHANGE_LOCATION') {
        return {
            ...state,
            demo: {
                ...state.demo,
                faceLocationToChange: action.payload
            }
        }
    }
    
    if(action.type === 'UPDATE_FACTOR') {
        return {
            ...state,
            admin: {
                selectedFactor: action.payload,
                skipBy: 0,
                limitBy: 30
            }
        }
    }

    if(action.type === "UPDATE_ADMIN_USER") {
        return {
            ...state,
            admin: {
                ...state.admin,
                candidates: action.payload.candidates,
                skipBy: parseInt(state.admin.skipBy) + parseInt(action.payload.newSkipBy)
            }
        }
    }

    if (action.type === 'UPDATE_ADMIN_SKIP') {
        return {
            ...state,
            admin: {
                ...state.admin,
                skipBy: parseInt(action.payload.skipBy)
            }
        } 
    }

    if (action.type === 'UPDATE_REPORT_DURATION') {
        return {
            ...state,
            admin: {
                ...state.admin,
                reportData: undefined,
                reportDuration: action.payload.reportDuration
            }
        }
    }

    if (action.type === "UPDATE_ADMIN_REPORT") {
        return {
            ...state,
            admin: {
                ...state.admin,
                reportData: action.payload.reportData
            }
        }
    }

    if(action.type === 'SET_NFSW') {
        return {
            ...state,
            isNsfw: {
                ...state.isNsfw,
                [action.payload.key]: false
            }
        }
    }
    
    if(action.type === 'UPDATE_USER_CREATED_PHOTOS') {
        return {
            ...state,
            user:{
                ...state.user,
                createdPhotos: action.payload.photos
            },
            createdPhotoSkipBy: action.payload.skipBy
        }
    }
    
    if(action.type === 'UPDATE_TEAM_DETAILS') {
        return {
            ...state,
            teams: action.payload
        }
    }

    if(action.type === 'UPDATE_SELECTED_TEAM') {
        return {
            ...state,
            selectedTeam: action.payload
        }
    }

    if(action.type === 'UPDATE_TEAM_NAME') {
        return {
            ...state,
            newTeam: {
                ...state.newTeam,
                name: action.payload,
                error: undefined
            }
        }
    }

    if(action.type === 'UPDATE_TEAM_MEMBER') {
        return {
            ...state,
            newTeam: {
                ...state.newTeam,
                members: action.payload
            }
        }
    }

    if(action.type === "NEW_TEAM_ERROR") {
        console.log("NEW_TEAM_ERROR: ", action.payload);

        return {
            ...state,
            newTeam: {
                ...state.newTeam,
                error: action.payload
            }
        }
    }

    if(action.type === "NEW_TEAM_INFO") {
        return {
            ...state,
            newTeam: {
                ...state.newTeam,
                info: action.payload
            }
        }
    }

    if(action.type === "UPDATE_ADMIN_START_DATE") {
        return {
            ...state,
            admin: {
                ...state.admin,
                startDate: action.payload.date
            }
        }
    }

    if(action.type === "UPDATE_ADMIN_END_DATE") {
        return {
            ...state,
            admin: {
                ...state.admin,
                endDate: action.payload.date
            }
        }
    }

    if(action.type === "ADD_IMAGE_TO_TEAM") {
        return {
            ...state,
            user: {
                ...state.user,
                createdPhotos: state.user.createdPhotos.map(photo => {
                    if(photo._id === action.payload.imageId && action.payload.status === "add") {
                        return {
                           ...photo,
                           team: {
                                ...photo.team,
                                [action.payload.teamId]: true
                           }
                        }
                    } else if(photo._id === action.payload.imageId && action.payload.status === "delete") {
                        return {
                           ...photo,
                           team: {
                                ...photo.team,
                                [action.payload.teamId]: false
                           }
                        }
                    } else {
                        return photo;
                    }
                })
                
            }
        }
    }

    throw new Error(`No Matching '${action.type}' - action type found.`);
}

export default global_reducer;