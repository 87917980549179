import React from 'react';
import { useGlobalContext } from '../../context/global_context';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'

const BannerFour = () => {
    const {
        user,
        isMobile,
        setNeedLogin
    } = useGlobalContext();

    return (
        <div className="slider-area banner-style-4 bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">
                <div className="container eduvibe-animated-shape">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-start">
                                    <h5>Just Explain The Expected Image</h5>
                                    <h5 className="title">
                                        <span style={{color: "#f38b01"}} >Studio Quality Photos, </span>
                                        <span style={{color: "#000"}} >using AI, </span>
                                        <span style={{color: "#f38b01"}} >within seconds</span>
                                    </h5>
                                    <span className="description"><b>With AI Created Faces Or</b></span>
                                    <br/>
                                    <span style={{color: "#f38b01"}}  className="description"><b><i className="icon-Star"></i> Just 1 Selfie Photo <i className="icon-Star"></i></b></span>
                                    <br />
                                    <button className={`edu-btn btn-medium btn-secondary left-icon left-btn rn-btn`}
                                        onClick={() => setNeedLogin(true)}>
                                        Signup & Get 10 Free Photos <i className="icon-arrow-right-line-right"></i>
                                    </button>
                                    <br/>
                                    <br/>
                                    <p className="mt--40 mt_md--20 mt_sm--20" style={{fontSize:'10px'}}>*No credit card required.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="banner-image">
                                <div className="thumbnail" style={{boxShadow: '2px 2px 2px 2px #e6e6e6'}}>
                                    {/* <img src="/images/banner/banner-04/1.png" alt="Banner Images" /> */}
                                    <ReactPlayer playing controls muted={true} url='https://youtu.be/GyMu7XH6qeA' width={isMobile ? "100%" : "640px"} height={isMobile ? "100%": "360px"} />
                                </div>
                                <Link to='' onClick={() => setNeedLogin(true)}>
                                <div className="learner-badge">
                                    <div className="badge-inner">
                                            <span className="viewer">With</span>
                                            <span className="viewer">Same</span>
                                            <span className="viewer">Face</span>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-05-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-05-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-19-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-01-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-5">
                            <img src="/images/shapes/shape-01-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-6">
                            <img src="/images/shapes/shape-06.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-left">
                        <img src="/images/shapes/shape-13-08.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-right">
                        <img src="/images/shapes/shape-13-09.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerFour;