import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const TermsAndConditions = () => {
    return (
        <>
            <SEO title="Terms And Conditions" />
            <Layout>
                <BreadcrumbOne
                    title="Terms & Conditions"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Terms & Conditions"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <h4 className="title">Let's Join InstaPhotoAI</h4>
                                <p>These terms and conditions outline the rules and regulations for the use of InstaPhotoAI's Website, located at InstaPhotoAI.com. By accessing this website we assume you accept these terms and conditions. Do not continue to use InstaPhotoAI if you do not agree to take all of the terms and conditions stated on this page. The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

                                <h4 className="title">Cookies</h4>
                                <p>We employ the use of cookies. By accessing InstaPhotoAI, you agreed to use cookies in agreement with the InstaPhotoAI's Privacy Policy. Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies</p>

                                <h4 className="title">License</h4>
                                    <p >
                                    Unless otherwise stated, InstaPhotoAI and/or its licensors own the
                                    intellectual property rights for all material on InstaPhotoAI. All
                                    intellectual property rights are reserved. You may access this from
                                    InstaPhotoAI for your own personal use subjected to restrictions set in
                                    these terms and conditions.
                                    </p>
                                    You must not:
                                    <ol type="1" ><li >
                                        Republish material from InstaPhotoAI.
                                    </li> <li >Sell, rent or sub-license material from InstaPhotoAI.</li> <li >
                                            Reproduce, duplicate or copy material from InstaPhotoAI.
                                        </li> <li >
                                            Redistribute content from InstaPhotoAI.
                                        </li></ol>
                                    This Agreement shall begin on the date hereof.
                                    <p >
                                        Parts of this website offer an opportunity for users to post and
                                        exchange opinions and information in certain areas of the website.
                                        InstaPhotoAI does not filter, edit, publish or review Comments prior to
                                        their presence on the website. Comments do not reflect the views and
                                        opinions of InstaPhotoAI,its agents and/or affiliates. Comments reflect
                                        the views and opinions of the person who post their views and
                                        opinions. To the extent permitted by applicable laws, InstaPhotoAI shall
                                        not be liable for the Comments or for any liability, damages or
                                        expenses caused and/or suffered as a result of any use of and/or
                                        posting of and/or appearance of the Comments on this website.
                                    </p> <p >
                                        InstaPhotoAI reserves the right to monitor all Comments and to remove any
                                        Comments which can be considered inappropriate, offensive or causes
                                        breach of these Terms and Conditions.
                                    </p>
                                    You warrant and represent that:
                                    <ol type="1" ><li >
                                        You are entitled to post the Comments on our website and have all
                                        necessary licenses and consents to do so
                                    </li> <li >
                                            The Comments do not invade any intellectual property right,
                                            including without limitation copyright, patent or trademark of any
                                            third party
                                        </li> <li >
                                            The Comments do not contain any defamatory, libelous, offensive,
                                            indecent or otherwise unlawful material which is an invasion of
                                            privacy
                                        </li> <li >
                                            The Comments will not be used to solicit or promote business or
                                            custom or present commercial activities or unlawful activity.
                                        </li></ol>
        <p>
                                    You hereby grant InstaPhotoAI a non-exclusive license to use, reproduce,
                                    edit and authorize others to use, reproduce and edit any of your
                                    Comments in any and all forms, formats or media.
                                </p>

                                <br/>
                                <h4 className="title">Hyperlinking to our Content</h4>
                                <div  >
                                    The following organizations may link to our Website without prior
                                    written approval:
                                    <ol type="1" ><li >
                                        Government agencies.
                                    </li> <li >
                                            Search engines.
                                        </li> <li >
                                            News organizations.
                                        </li> <li >
                                            Online directory distributors may link to our Website in the same
                                            manner as they hyperlink to the Websites of other listed businesses.
                                        </li> <li >
                                            System wide Accredited Businesses except soliciting non-profit
                                            organizations, charity shopping malls, and charity fundraising
                                            groups which may not hyperlink to our Website.
                                        </li></ol> <p >
                                        These organizations may link to our home page, to publications or to
                                        other Website information so long as the link: (a) is not in any way
                                        deceptive; (b) does not falsely imply sponsorship, endorsement or
                                        approval of the linking party and its products and/or services; and
                                        (c) fits within the context of the linking party’s site.
                                    </p>
                                    We may consider and approve other link requests from the following types
                                    of organizations:
                                    <ol type="1" ><li >
                                        commonly-known consumer and/or business information sources.
                                    </li> <li >
                                            dot.com community sites.
                                        </li> <li >
                                            associations or other groups representing charities.
                                        </li> <li >
                                            online directory distributors.
                                        </li> <li >
                                            internet portals.
                                        </li> <li >
                                            accounting, law and consulting firms.
                                        </li> <li >
                                            educational institutions and trade associations.
                                        </li></ol> <p >
                                        We will approve link requests from these organizations if we decide
                                        that: (a) the link would not make us look unfavorably to ourselves or
                                        to our accredited businesses; (b) the organization does not have any
                                        negative records with us; (c) the benefit to us from the visibility of
                                        the hyperlink compensates the absence of InstaPhotoAI; and (d) the link is
                                        in the context of general resource information.
                                    </p> <p >
                                        These organizations may link to our home page so long as the link: (a)
                                        is not in any way deceptive; (b) does not falsely imply sponsorship,
                                        endorsement or approval of the linking party and its products or
                                        services; and (c) fits within the context of the linking party’s site.
                                    </p> <p >
                                        If you are one of the organizations listed in paragraph 2 above and
                                        are interested in linking to our website, you must inform us by
                                        sending an e-mail to InstaPhotoAI. Please include your name, your
                                        organization name, contact information as well as the URL of your
                                        site, a list of any URLs from which you intend to link to our Website,
                                        and a list of the URLs on our site to which you would like to link.
                                        Wait 2-3 weeks for a response.
                                    </p>
                                    Approved organizations may hyperlink to our Website as follows:
                                    <ol type="1" ><li >
                                        By use of our corporate name.
                                    </li> <li >
                                            By use of the uniform resource locator being linked to.
                                        </li> <li >
                                            By use of any other description of our Website being linked to
                                            that makes sense within the context and format of content on the
                                            linking party’s site.
                                        </li></ol>
                                    No use of InstaPhotoAI's logo or other artwork will be allowed for linking
                                    absent a trademark license agreement.
                                </div>
                                <br/>

                                <h4 className="title">Disclaimer</h4>
                                <div  >
                                    To the maximum extent permitted by applicable law, we exclude all
                                    representations, warranties and conditions relating to our website and
                                    the use of this website. Nothing in this disclaimer will:
                                    <ol ><li >
                                        limit or exclude our or your liability for death or personal
                                        injury.
                                    </li> <li >
                                            limit or exclude our or your liability for fraud or fraudulent
                                            misrepresentation.
                                        </li> <li >
                                            limit any of our or your liabilities in any way that is not
                                            permitted under applicable law.
                                        </li> <li >
                                            exclude any of our or your liabilities that may not be excluded
                                            under applicable law.
                                        </li></ol> <p >
                                        The limitations and prohibitions of liability set in this Section and
                                        elsewhere in this disclaimer: (a) are subject to the preceding
                                        paragraph; and (b) govern all liabilities arising under the
                                        disclaimer, including liabilities arising in contract, in tort and for
                                        breach of statutory duty.
                                    </p> <p >
                                        As long as the website and the information and services on the website
                                        are provided free of charge, we will not be liable for any loss or
                                        damage of any nature.
                                    </p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default TermsAndConditions;