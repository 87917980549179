import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import { useGlobalContext } from '../../context/global_context';

const FaceReplacement = () => {
    const {
        user,
        setNeedLogin
    } = useGlobalContext();

    return (
        <div className=" eduvibe-home-four-about edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row row--100">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="">
                                <img className="image-1" src="/images/banner/banner-04/6.png" alt="About Images" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1" >
                    </div>
                    <div className="col-lg-5">
                        <div className="inner" >
                            <br/>
                            <SectionTitle
                                classes = "text-start"
                                slogan = "Use A Base Photo & User's Photo"
                                title = "Replace Person's Face In The Photo"
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <p className="description mt--40 mt_md--20 mt_sm--20">Have an extraordinary photograph, yet need to change the individual in that photograph? Make identical photograph with only a face change.</p>
                            </ScrollAnimation>

                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn mt--40"
                                animateOnce={true}
                            >
                                <button className={`edu-btn btn-medium btn-secondary left-icon left-btn rn-btn`}
                                        onClick={() => setNeedLogin(true)}>
                                        Signup & Get 10 Free Photos <i className="icon-arrow-right-line-right"></i>
                                    </button>
                                    <br/>
                                    <p className="mt--40 mt_md--20 mt_sm--20" style={{fontSize:'13px'}}>*No credit card required.</p>
                                
                            </ScrollAnimation>

                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaceReplacement;