import { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderSticky from './HeaderSticky';
import { useGlobalContext } from '../../context/global_context';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const HeaderOne = ({ styles, disableSticky }) => {
    const {
        user,
        activeMenu,
        updatedActiveMenu,
        fetchUser,
        setNeedLogin,
        fetchExamples
    } = useGlobalContext();

    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prevState => !prevState);
    }

    const onSearchHandler = () => {
        setSearchPopup(prevState => !prevState);
    }

    if (searchPopup) {
        document.body.classList.add('search-popup-active');
    } else {
        document.body.classList.remove('search-popup-active');
    }

    const sticky = HeaderSticky(118);
    const classes = sticky ? 'sticky' : '';
    const stickyStatus = disableSticky ? '' : ' header-sticky';
    return (
        <>
        <div style={{backgroundColor:'blue', width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color:'#fff', padding:'1px'}}
            ><text style={{fontSize:'12px'}}> 📣 📣 Visit Our New AI Platform Designed for Pro AI Creators ✨ <a href="https://rainforest.zone" target="_blank" style={{color: '#fff'}}><b><u>Rainforest.Zone</u></b></a> 🎉 🎉</text></div>
            
            <header className={`edu-header disable-transparent ${stickyStatus} ${styles || ''} ${classes || ''}`}>
            
                <div className="container">
                
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                            <div className="logo">
                                <Link to={(user && user.token) ? "/ai-portrait" : "/"}>
                                    <img className='logo-rocket' src="/images/logo/rocket.png" />
                                    <img className="logo-light" src="/images/logo/logo.png" alt="Main Logo" width="60%" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-8 d-none d-xl-block">
                            <nav className="mainmenu-nav d-none d-lg-block">
                            </nav>
                        </div>

                        <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                            <div className="header-right d-flex justify-content-end">
                                <div className="header-quote">
                                    <div className="quote-icon quote-user">
                                            <span className='edu-btn btn-small' style={{ fontSize: '16px', color: 'blue', textDecoration: activeMenu === 'blogs' ? 'underline' : 'none' }} onClick={() => { window.location.href = '/blogs' }}>Blogs</span>
                                                &nbsp;&nbsp;&nbsp;
                                        {user.token
                                            ? <span className='edu-btn btn-small' style={{ fontSize: '16px', color: 'blue', textDecoration: activeMenu === 'register' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('profile'); fetchUser(); window.location.href = '/my-profile' }}>{activeMenu === 'profile' ? <AccountCircleIcon fontSize='large' /> : <AccountCircleOutlinedIcon fontSize='large' />}</span>
                                            
                                            
                                            : <>
                                                
                                                <span className='edu-btn btn-small' style={{ fontSize: '16px', color: 'blue', textDecoration: activeMenu === 'ai-photo-examples' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('ai-photo-examples'); fetchExamples(); window.location.href = '/ai-photo-examples' }}>Examples</span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className='edu-btn btn-small' style={{ fontSize: '16px', color: 'blue' }} onClick={() => setNeedLogin(true)}>Login</span>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </header>
            
        </>
    )
}

export default HeaderOne;