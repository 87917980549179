import React, {useState} from 'react'
import SEO from '../../common/SEO';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useGlobalContext } from '../../context/global_context';
import Grid from '@material-ui/core/Grid'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

function Examples() {
    const [selectedExample, setSelectedExample] = useState("");

    const {
        path,
        updatePath,
        examples,
        fetchExamples
    } = useGlobalContext();

    if (path != '/ai-photo-examples') {
        updatePath('/ai-photo-examples');
    }

    if(examples.length === 0) {
        fetchExamples();
    }

    const handleCopyPrompt = (id, prompt) => {
        navigator.clipboard.writeText(prompt);
        setSelectedExample(id)
    }
  return (
    <>
        <SEO title="InstaPhotoAI" />
            <LayoutAfterLogin>
                <BreadcrumbOne 
                    title="AI Photo Examples"
                    parentUrl="Home"
                    currentUrl="AI Photo Examples"
                />
                <div className="login-register-page-wrapper edu-section-gap bg-color-white">
                    <div className="container checkout-page-style">
                        <Grid container direction='row'>
                            {examples.map((ex,i) => {
                                return <Grid key={ex.id} item xs={10} lg={3}  style={{border:'1px solid #6F6B80', padding:'8px', margin:'4px'}}>
                                     <Grid container direction='column'>
                                        <b>{(i+1)}/{examples.length}: {ex.caption}</b>
                                        <img src={ex.imageAWSLink}/>
                                        <span><b>Prompt:</b> {ex.prompt}</span>
                                        {selectedExample === ex.id ?
                                            <b style={{color:'#7152E9'}}><CheckIcon/>Prompt Copied</b>
                                            : <button className='edu-btn btn-small' onClick={() => handleCopyPrompt(ex.id, ex.prompt)}><ContentCopyIcon/>Copy Prompt</button>}

                                    </Grid>
                                    <br/>
                                    </Grid>
                            })}
                        </Grid>
                    </div>
                </div>
            </LayoutAfterLogin>
    </>
    
  )
}

export default Examples