import Grid from '@material-ui/core/Grid'

const Blog_4 = () => {
    return (
      <>
      <div>
      <p><span style={{fontSize: '16pt', fontWeight:'500'}}>
AI being the talk of the town and taking over the digital space in various fields, staying updated on artificial intelligence (AI) developments becomes crucial if you don't want to be left behind.</span></p>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}> Here's a curated list of the top 10 AI influencers who are visionary thinkers shaping the AI scene and leading conversations in various fields about AI.</span></p>

      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>
1. Sam Altman </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/1.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/2.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/3.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/4.png"/></Grid>
      </Grid>
      <br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}> Sam Altman has a huge following of 2.5M followers on X . He actively shares updates about Open AI's progress and innovation in AI tools while contributing to his Apollo projects.</span><a href="https://en.wikipedia.org/wiki/Sam_Altman" target="_blank"><u> Know more about Sam Altman</u></a></p>
      <br/>

      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}>2. Dr Fei-Fei Li</span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/5.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/6.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/7.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/8.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

Dr Fei-Fei has a huge following of 445k+ followers on X and invented the ImageNet and ImageNet challenge, a large-scale data and benchmarking effort contributing to the latest AI development and deep learning.</span><a href="https://en.wikipedia.org/wiki/Fei-Fei_Li" target="_blank"><u> Know more about Dr Fei-Fei Li</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 3. Andrew Ng </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/9.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/10.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/11.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/12.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

Andrew Ng has a huge following of 943k followers on X . He shares his expertise and vision for AI education and application. He continues to publish seminal research advancing deep learning.</span><a href="https://en.wikipedia.org/wiki/Andrew_Ng" target="_blank"><u> Know more about Andrew Ng</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 4. Kate Crawford </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/13.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/14.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/15.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/16.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

Kate Crawford has 83k followers on X, she's a notable expert studying artificial intelligence society. Her work spans two decades of AI revolution and focuses on understanding how big data, machine learning and AI affect history, politics, labor, and the environment.</span><a href="https://en.wikipedia.org/wiki/Kate_Crawford" target="_blank"><u> Know more about Kate Crawford</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 5. Ian Goodfellow </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/17.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/18.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/19.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/20.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

He's a computer scientist and engineer, who made a significant impact in the field of artificial neural networks and deep learning.</span></p>
 
<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}> He conceptualized the idea of computers generating images independently free from human intervention.</span><a href="https://en.wikipedia.org/wiki/Ian_Goodfellow" target="_blank"><u> Know more about Ian Goodfellow</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 6. Allie Miller </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/21.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/22.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/23.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/24.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

An AI entrepreneur, advisor and investor. Has over one million followers on LinkedIn.She actively contributes to AI education, speaks of AI worldwide, advises global AI public policy and creates educational resources for businesses to excel with AI. She's worth following for AI and tech news, and insights.</span><a href="https://twitter.com/alliekmiller?lang=en" target="_blank"><u> Know more about Allie K. Miller</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 7. Tabitha Goldstaub </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/25.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/26.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/27.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/28.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}> 

Tabitha Goldstaub is a tech entrepreneur focused on the impact of AI. She co-founded CogX, an online platform that gathers researchers and policymakers to discuss AI advancements and served as the chair of the UK government's AI council.
</span></p>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}> She has 15k+ followers on LinkedIn and 14k+ followers on X . She's actively involved with organizations like TechUk and the Alan Tiring Institute, advising AI and tech matters.</span><a href="https://en.wikipedia.org/wiki/Tabitha_Goldstaub" target="_blank"><u> Know more about Tabitha Goldstaub</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 8. Yann Le Cun </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/29.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/30.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/31.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/32.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

Yann LeCun, the chief AI scientist at Facebook and a respected professor at New York University has a significant following of 625k on LinkedIn and 641k on Twitter. He is well known for his expertise in AI, machine learning, computer vision, and robotics.
</span></p>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}> He currently leads AI research at Facebook, and is a vocal defender of AI technology, claiming that “AI doomers is doomed.”</span><a href="https://en.wikipedia.org/wiki/Yann_LeCun" target="_blank"><u> Know more about Yann LeCun</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 9. Timnit Gebru </span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/33.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/34.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/35.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/36.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

She's a computer scientist from Eritrea and Ethiopia, with a substantial following of 165k on Twitter and 40k on LinkedIn. She researches artificial intelligence, focusing on bias in algorithms and data mining. She is the founder and executive director of the Distributed Artificial Intelligence Research Institute (DAIR). </span><a href="https://en.wikipedia.org/wiki/Timnit_Gebru" target="_blank"><u> Know more about Timnit Gebru</u></a></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 10. Rachel Thomas</span></h1>
<Grid
              container
              direction="row"
              justifyContent="center"
              alignItems='center'
              spacing={1}
          >
              <Grid item xs><img src="/images/blogs/4/37.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/38.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/39.png"/></Grid>
              <Grid item xs><img src="/images/blogs/4/40.png"/></Grid>
      </Grid>

<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>
Rachel Thomas is a computer scientist and the co-founder of fast.ai. which makes AI education accessible. She teaches courses on responsible AI development so practitioners mitigate harmful biases. </span></p>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}> Her influence extends far and wide, with her writing, reaching over a million readers and being translated into Chinese, Spanish, Korean, and Portuguese, and earning spots on the front page of Hacker News nine times. </span><a href="https://en.wikipedia.org/wiki/Yann_LeCun" target="_blank"><u> Know more about Rachel Thomas</u></a></p>

<br />
<br />
<p><span style={{fontSize: '16pt', fontWeight:'500'}}> That said on top of AI influencers taking over the digital space, below is also a documented list of relevant online forums and groups, including their platforms, target audience description and potential reach. </span></p>

<h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 1. Open Developer Forum </span></h1>


<br/>
<p><span style={{fontSize: '16pt', fontWeight:'500'}}>
This is a forum that centers its discussion on the following themes:
</span></p>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}> 1. Insertion of young people in artificial intelligence
<br/> 2. A path to AI developer
<br/>3. Embracing Innovation: Standing at a Digital Crossroads
<br/> 4. Technical Founding Team Members of Gen-AI in higher education start-up </span></p>


<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 2. INSPIRE Youth Forum -summer school </span></h1>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

The forum is designed to inform, inspire, and engage students. In live, online sessions, participants will benefit from exchanging with experts in forward-thinking fields. </span></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 3. Teens in AI </span></h1>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

Teens in Action is a unique online platform where global AI leaders empower and engage young minds in thought-provoking conversations and discussions centered around AI-based topics. </span></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 4. Global Youth Forum </span></h1>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

This is a forum hosted by CIGI and the Balsillie School of International Affairs. This forum is centered on data, privacy and artificial intelligence (AI). </span></p>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}> Students learn about the complexities surrounding data, privacy and AI by exploring the social, ethical, legal, economic, and political issues behind this subject. </span></p>


<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 5. World Youth Forum </span></h1>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

This is a forum that engages youth from around the globe in an enriching set-up, allowing them to exchange views and recommend initiatives to decision-makers and influential figures. </span></p>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}> The World Youth Forum focuses on topics such as the industrial revolution, food security, artificial intelligence, blockchain, women empowerment, and arts and cinema. </span></p>

<br />
      <h1><span style={{fontSize: '20pt', fontWeight:'800'}}> 6.Youth4Work </span></h1>

<p><span style={{fontSize: '16pt', fontWeight:'500'}}>

This is an online discussion forum where youth or even experienced professionals discuss their queries related to artificial intelligence and get answers to their questions from other talented individuals. An online discussion is started by asking questions and helping others with answers. </span></p>
      <p style={{fontSize: '16pt', fontWeight:'500'}}>Login to <a href="https://instaphotoai.com" target="_blank"><u style={{color:"blue"}}>InstaPhotoAI</u></a> for stunning AI photos.</p>
    </div>
      </>)
}

export default Blog_4;