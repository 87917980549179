import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutFive from '../../components/about/AboutFive';
import WhyUs from '../../components/home-five/WhyUs';
import HomeFourAbout from '../../components/home-four/HomeFourAbout';
const AboutUs = () => {
    
    return (
        <>
            <SEO title="About Us" />
            <Layout>
                <BreadcrumbOne 
                    title="About Us"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About Us"
                />

                <AboutFive wrapperClass="edu-section-gapTop" />

                <br/>

                <WhyUs />

                <HomeFourAbout />

            </Layout>
        </>
    )
}

export default AboutUs;